// libraries
import React from "react";

// components
import { GroupSelect } from "../../../components/admin-components";
import { WithDrawPopup, SetPrizePopup } from "../../../components/commons";

const GroupSelectPage = () => {
  return (
    <>
      <GroupSelect />
      <WithDrawPopup />
      <SetPrizePopup />
    </>
  );
};

export default GroupSelectPage;