//libraries
import React from "react";

// constants
import { eng_lang } from "../../../lib/utills/constants";

//styles
import "./index.scss";

//assets
import { HowToExport, paw } from "../../../assets";

//components
import { BlockButton } from "../../commons";

const HowItWorks = () => {
  return (
    <section className="HowItWorks" id="howItWorks">
      <div className="row ps-lg-5 HowItWorks__margin-tp-btm">
        <div className="col-lg-5">
          <div className=" d-flex justify-content-center">
            <img className="cat-in-box" src={HowToExport} alt="HowToExport" />
          </div>
        </div>
        <div className="col-lg-7 HowItWorks__text-section">
          <h2 className="text-white">{eng_lang.howItWorks.heading}</h2>

          <p className="working-text mt-24">
            {eng_lang.howItWorks.first_line} <br />
            {eng_lang.howItWorks.second_line} <br />
            {eng_lang.howItWorks.third_line}
          </p>
          <p className="working-text mt-24 prizes-list">
            <b className="text-white">{eng_lang.howItWorks.first}</b>{" "}
            {eng_lang.howItWorks.first_prize_line}
          </p>
          <p className="working-text prizes-list">
            <b className="text-white">{eng_lang.howItWorks.next}</b>
            {eng_lang.howItWorks.next_prize_line}
          </p>
          <p className="working-text prizes-list">
            <b className="text-white">{eng_lang.howItWorks.then}</b>
            {eng_lang.howItWorks.third_prize_line} <br />{" "}
            {eng_lang.howItWorks.third_prize_line_2}
          </p>
          <p className="working-text mt-20 prizes-list">
            {eng_lang.howItWorks.lastly}{" "}
            <b className="text-white">{eng_lang.howItWorks.prizes}</b> are paid
            based on how many of the killer’s traits your customized cat has!
          </p>
          <div className="mt-24 prize-tiers">
            <img className="paw" src={paw} alt="paw" />
            <p>$100,000 for all 6 traits correct</p>
          </div>

          <div className="mt-24 prize-tiers">
            <img className="paw" src={paw} alt="paw" />
            <p>$5,000 for 5/6 traits correct</p>
          </div>

          <div className="mt-24 prize-tiers">
            <img className="paw" src={paw} alt="paw" />{" "}
            <p>$1,000 for 4/6 traits correct</p>
          </div>

          <div className="mt-56 learn-btn">
            <BlockButton
              showImg={false}
              imgPath={""}
              text={"Click Here For Full Rules"}
              data_bs_toggle={"modal"}
              data_bs_target={"#scrollModalWorks"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;