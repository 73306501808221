// axios methods
import axios from "axios"

// action types
import { types } from "../../types";

// constants
import { api_routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";

const getToggleAccessAction = (header) => {
  return async function (dispatch) {
    try {
      // api call to get total toggleSuccess
      let response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${api_routes.TOGGLE}`, {headers: header})
      if (response) dispatch(toggleSuccess(response?.data?.data?.toggleSetAttributesAndStoryPage));
    } catch (err) {
      dispatch(toggleAccessFailure());
    }
  };
};

const toggleSuccess = (toggle) => {
  return function (dispatch) {
    dispatch({
      type: types.GET_TOGGLE_USER,
      payload: toggle,
    });
  };
};

const toggleAccessFailure = () => {
  return function (dispatch) {
    dispatch({
      type: types.TOGGLE_FAIL,
      payload: false,
    });
  };
};

export default getToggleAccessAction;
