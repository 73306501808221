const withDraw = (tokenInstance, walletAddress) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .withdraw()
      .send({ from: walletAddress })
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default withDraw;
