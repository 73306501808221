import React from "react";
import { useNavigate } from "react-router-dom";

// components
import { BlockButton } from "../../components/commons";

// constants
import { eng_lang, routes } from "../../lib/utills/constants";

const Error = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(routes.HOME);
  };
  return (
    <div className="error-page d-flex flex-column align-items-center">
      <h2 className="text-white mb-3 text-uppercase">Error 404</h2>
      <h3 className="text-white">Page not Found</h3>
      <div className="mt-3">
        <BlockButton
          showImg={false}
          text={eng_lang.error_pg_button_text}
          imgPath={""}
          name={""}
          handleClick={handleNavigation}
        />
      </div>
    </div>
  );
};

export default Error;
