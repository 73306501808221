import React, { useContext, Fragment } from "react";

import { landinPageProps } from "../../../pages/landing-page";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";
import { eng_lang } from "../../../lib/utills/constants";
// import { OPEN_SEA_URL, BASE_OPEN_SEA_URL } from "../../../enviroments";

const OtherWinnersSection = ({ winner_avatar }) => {
  const { winnerData } = useContext(landinPageProps);
  const { walletAddressTruncater } = useContext(EtheriumContext);
  return (
    <>
      {winnerData?.winners?.map((winner, index) => {
        return (
          <Fragment key={index}>
            {winner?.groupName !== eng_lang.groupOne && (
              <div className="row list-items">
                <div
                  className={`col-sm-4 justify-content-sm-start justify-content-center d-flex align-items-center `}
                >
                  <div className="gap-2">
                    <a
                      href={`${process.env.REACT_APP_BASE_OPEN_SEA_URL}${winner?.owner?.ownerAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="other-winners-avatars">
                        <img
                          src={
                            winner?.owner?.openSeaProfile?.avatarUrl ||
                            winner_avatar
                          }
                          alt={"winner-avatar"}
                          className="winners-pic"
                        />
                      </div>
                    </a>
                  </div>
                  <a
                    href={`${process.env.REACT_APP_BASE_OPEN_SEA_URL}${winner?.owner?.ownerAddress}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`inter text-white text-break names`}
                  >
                    {!winner?.owner?.openSeaProfile?.userName ||
                    winner?.owner?.openSeaProfile?.userName === "Unnamed"
                      ? walletAddressTruncater(winner?.owner?.ownerAddress)
                      : winner?.owner?.openSeaProfile?.userName}
                  </a>
                </div>

                <div className="col-sm-4 text-center">
                  <a
                    href={`${process.env.REACT_APP_OPEN_SEA_URL}${winner?.suspectedCat?.nft}`}
                    target="_blank"
                    rel="noreferrer"
                    className="inter text-white text-decoration-underline get-cat"
                  >
                    {winner?.suspectedCat?.placardNumber?.toString().length ===
                    5
                      ? ` Cat#0${winner?.suspectedCat?.placardNumber}`
                      : ` Cat#${winner?.suspectedCat?.placardNumber}`}
                  </a>
                </div>
                <div className="col-sm-4 text-center text-sm-end">
                  <h5 className="text-white mb-sm-0 mb-3 mt-3 mt-sm-0">
                    $
                    {winner?.owner?.prizeAmountTransferred?.toLocaleString() ||
                      0}
                  </h5>
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default OtherWinnersSection;
