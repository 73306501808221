// axios methods
import axios from "axios";

// // action types
import { types } from "../../types";

// constants
import { api_routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";

const setPrizeAction = (header) => {
  return async function (dispatch) {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${api_routes.GET_GROUPS}1&pageSize=100`,
        {},
        { headers: header }
      );
      if (response) {
        dispatch({
          type: types.SET_PER_PRIZE,
          payload: [
            response?.data?.data[0]?.prizePerCat,
            response?.data?.data[1]?.prizePerCat,
            response?.data?.data[2]?.prizePerCat,
          ],
        });
        dispatch({
          type: types.SET_TOTAL_PRIZE,
          payload: [
            response?.data?.data[0]?.totalGroupPrize,
            response?.data?.data[1]?.totalGroupPrize,
            response?.data?.data[2]?.totalGroupPrize,
          ],
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: types.FAIL_SET_PRIZE,
      });
    }
  };
};

export const resetPrizeAction = () => {
  return async function (dispatch) {
    dispatch({
      type: types.FAIL_SET_PRIZE,
    });
  }
}

export default setPrizeAction;
