export const types = {
  MINTED_NFTS_COUNT: "GET_MINTED_NFTS",
  SET_WALLET_CONNECTED: "SET_WALLET_CONNECTED",
  WALLET_ADDRESS: "WALLET_ADDRESS",
  CONNECT_WALLET_BTN_LOADING: "CONNECT_WALLET_BTN_LOADING",
  CREATE_VOUCHER: "CREATE_VOUCHER",
  VOUCHER_LOADING: "VOUCHER_LOADING",
  VOUCHER_RECIEVED: "VOUCHER_RECIEVED",
  BUY_NFT: "BUY_NFT",
  BUY_IN_PROGRESS: "BUY_IN_PROGRESS",
  BUY_ERROR: "BUY_ERROR",
  NEW_NFT_MINTED: "NEW_NFT_MINTED",
  MINT_SUCCESFULL: "MINT_SUCCESFULL",
  SHOW_MODAL: "SHOW_MODAL",
  TOKEN_ID: "TOKEN_ID",
  CLAIM_SUSPECT_LOADING: "CLAIM_SUSPECT_LOADING",
  CLAIM_NFT_AMOUNT: "CLAIM_NFT_AMOUNT",
  DISPAY_MODAL: "DISPAY_MODAL",
  ERROR_MSG: "ERROR_MSG",
  CLAIM_SUCCESS_MSG: "CLAIM_SUCCESS_MSG",
  NFT_INDEX: "NFT_INDEX",
  WINNER_REVEAL: "WINNER_REVEAL",
  WINNER_STATUS: "WINNER_STATUS",
  SET_NFT_AMOUNT: "SET_NFT_AMOUNT",
  INCREMENT_AMOUNT: "INCREMENT_AMOUNT",
  DECREMENT_AMOUNT: "DECREMENT_AMOUNT",
  SET_DEFAULT_AMOUNT: "SET_DEFAULT_AMOUNT",
  AUTH_USER: "AUTH_USER",
  AUTH_FAIL: "AUTH_FAIL",
  SET_CONGRATS: "SET_CONGRATS",
  RESET_CONGRATS: "RESET_CONGRATS",
  TOGGLE_ACCESS: "TOGGLE_ACCESS",
  TOGGLE_FAIL: "TOGGLE_FAIL",
  SET_MODAL_DATA: "SET_MODAL_DATA",
  UPDATE_PRIZE: "UPDATE_PRIZE",
  SET_PER_PRIZE: "SET_PER_PRIZE",
  SET_TOTAL_PRIZE: "SET_TOTAL_PRIZE",
  FAIL_SET_PRIZE: "FAIL_SET_PRIZE",
  GET_TOGGLE_USER: "GET_TOGGLE_USER",
  GET_WINNER_REVEAL_ADMIN: "GET_WINNER_REVEAL_ADMIN",
  GET_WINNER_REVEAL_ADMIN_FAILED: "GET_WINNER_REVEAL_ADMIN_FAILED"
};
