// libraries
import React, { useContext } from "react";

// components

// constants
import { eng_lang } from "../../../../lib/utills/constants";
import { landinPageProps } from "../../../../pages/landing-page";

// assets
import { Undeline } from "../../../../assets";

const BuyBody = () => {
  const { amount } = useContext(landinPageProps);

  return (
    <>
      <h2 className="text-white text-center">
        {eng_lang.buy_flow_modal.transaction_detail}
      </h2>
      <div className="sm-modal__body-margin">
        <div className="d-flex justify-content-between align-items-start">
          <p className="text-white">{eng_lang.buy_flow_modal.nft_price}</p>
          <h5 className="text-white">
            {eng_lang.dollar}
            {eng_lang.cat_price}
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          <p className="text-white">{eng_lang.buy_flow_modal.no_of_cats}</p>
          <h5 className="text-white"> {amount} </h5>
        </div>
        <img src={Undeline} alt="Undeline" className="w-100" />
        <div className="d-flex justify-content-between align-items-baseline">
          <p className="text-white">{eng_lang.buy_flow_modal.total_price}</p>
          <div className="d-flex flex-column align-items-end">
            <h5 className="text-white">
              {eng_lang.dollar}
              {(amount * eng_lang.cat_price)?.toLocaleString()}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBody;
