//libraries
import React from "react";
import { useNavigate } from "react-router-dom";

// components
import { BlockButton } from "../../commons";

// constants
import { eng_lang, routes } from "../../../lib/utills/constants";

//styles
import "./index.scss";

//assets
import { HeroImgPng } from "../../../assets";

const StoryClues = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(routes.CLAIM_ATTRIBUTE);
  };

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide carousel__carousel-section"
      data-bs-ride="carousel"
      data-bs-wrap={false}
      data-bs-touch={true}
      data-bs-interval={false}
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <p className="text-center">
            {eng_lang.story_clues.paragrapgh_line_1}
            {/* <br />
            {eng_lang.story_clues.paragrapgh_line_2} */}
          </p>
          <img
            src={HeroImgPng}
            className="d-block w-100 carousel__max-height"
            alt="..."
          />
        </div>
        <div className="carousel-item">
          <p className="text-center">
            {/* {eng_lang.story_clues.paragrapgh_line_1}
            <br /> */}
            {eng_lang.story_clues.paragrapgh_line_2}
          </p>
          <img
            src={HeroImgPng}
            className="d-block w-100 carousel__max-height"
            alt="..."
          />
        </div>
        <section className="d-flex justify-content-between carousel__spacing">
        <div className="carousel__min-width-prev d-none">
          <BlockButton
            showImg={false}
            text={"Previous"}
            imgPath={""}
            name={"previous"}
            handleClick={() => {}}
            transparent_btn={true}
            data_bs_target="#carouselExampleControls"
            data_bs_slide="prev"
          />
          </div>
        <div className="carousel__min-width-prev disabled">
          <BlockButton
            showImg={false}
            text={"Previous"}
            imgPath={""}
            name={"previous"}
            handleClick={() => {}}
            transparent_btn={true}
            data_bs_target="#carouselExampleControls"
            data_bs_slide="prev"
            disable={true}
          />
          </div>
          <div className="carousel__min-width">
            <BlockButton
              showImg={false}
              text={"Next"}
              imgPath={""}
              name={"next"}
              handleClick={() => {}}
              secondary={false}
              data_bs_target="#carouselExampleControls"
              data_bs_slide="next"
            />
          </div>

          <div className="carousel__min-width d-none">
            <BlockButton
              showImg={false}
              text={"Set Traits"}
              imgPath={""}
              name={""}
              handleClick={handleNavigation}
              secondary={false}
              data_bs_target=""
              data_bs_slide=""
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default StoryClues;
