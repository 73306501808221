import { types } from "../../types";

const initialState = {
  totalMinted: 0,
  totalClaimed: 0,
  btnLoading: false,
  showModal: "",
  toggleAccessUser: false,
};

const nftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MINTED_NFTS_COUNT:
      return {
        ...state,
        totalMinted: action.payload,
      };
    case types.CLAIM_NFT_AMOUNT:
      return {
        ...state,
        totalClaimed: action.payload,
      };
    case types.CONNECT_WALLET_BTN_LOADING:
      return {
        ...state,
        btnLoading: action.payload,
      };
    case types.SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case types.GET_TOGGLE_USER:
      return {
        ...state,
        toggleAccessUser: action.payload,
      };
    case types.TOGGLE_FAIL:
      return {
        ...state,
        toggleAccessUser: action.payload,
      };

    default:
      return state;
  }
};
export default nftsReducer;
