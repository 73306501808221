// libraries
import { combineReducers } from "redux";

// reducers
import nftsReducer from "./nfts/nftsReducer";
import metaMaskWalletReducer from "./blockchain/metaMaskWalletReducer";
import voucherReducer from "./voucher/voucherReducer";
import buyReducer from "./buy-flow/buyFlowReducer";
import claimReducer from "./claim-attributes/claimReducer";
import winnerReducer from "./winner-reveal/winnerReducer";
import setAmountReducer from "./set-amount/setAmountReducer";
import authReducer from "./auth/authReducer";
import toggleReducer from "./toggle-access/toggleReducer";
import setModalDataReducer from "./set-modal-data/setModalDataReducer";
import setPrizeReducer from "./set-prize/setPrizeReducer";

export default combineReducers({
  nftsReducer,
  setAmountReducer,
  metaMaskWalletReducer,
  voucherReducer,
  buyReducer,
  claimReducer,
  winnerReducer,
  authReducer,
  toggleReducer,
  setModalDataReducer,
  setPrizeReducer
});
