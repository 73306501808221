// constants
import { types } from "../../types";

const setModalDataAction = (groupName) => {
  return function (dispatch) {
    dispatch({
        type: types.SET_MODAL_DATA,
        payload: groupName,
      });
  };
};



export default setModalDataAction;
