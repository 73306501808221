// libraries
import React from "react";

// components

// constants
import { eng_lang } from "../../../../lib/utills/constants";

// assets

const WithDrawBody = ({
  balance,
  wallet,
  contractAddress,
  connected,
  adminAddress,
}) => {
  return (
    <>
      <h2 className="text-white text-center">
        {eng_lang.withdraw_modal.title}
      </h2>
      <div className="sm-modal__body-margin">
        <div className="d-flex  flex-xl-row flex-column justify-content-between align-items-center">
          <p className="text-white">{eng_lang.withdraw_modal.balance}</p>
          <p className="text-white withDraw-details gochi">
            {eng_lang.dollar}
            {balance?.toLocaleString()}
          </p>
        </div>
        <div className="d-flex mt-12 gap-3 flex-xl-row flex-column  justify-content-between align-items-center">
          <p className="text-white">
            {eng_lang.withdraw_modal.contract_address}
          </p>
          <p className="text-white withDraw-details gochi">{contractAddress}</p>
        </div>
        <div className="d-flex mt-12 gap-3  flex-xl-row flex-column  justify-content-between align-items-center">
          <p className="text-white">
            {eng_lang.withdraw_modal.reciever_address}
          </p>
          <p className="text-white withDraw-details gochi">
            {connected && wallet === adminAddress.toLowerCase()
              ? adminAddress
              : "Please Connect Your Admin Wallet Address"}
          </p>
        </div>
      </div>
    </>
  );
};

export default WithDrawBody;
