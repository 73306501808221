// libraries
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// styles
import "./style.scss";

// constants
import { eng_lang } from "../../../lib/utills/constants";

// redux actions
import { passwordUpdateAction } from "../../../redux/actions/auth-user";

// validation schema for email and password
import { passwordValidationSchema } from "./password-validation-schema/passwordValidationSchema";

const AdminPasswordUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    authReducer: { accessToken },
    nftsReducer: { btnLoading },
  } = useSelector((state) => state);

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      conPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: (values) => {
      dispatch(
        passwordUpdateAction(
          values.oldPassword,
          values.newPassword,
          header,
          navigate
        )
      );
    },
  });
  return (
    <div className="admin-login-bg">
      <div className="login-form">
        <h1 className="text-white admin-login-title">{eng_lang.title}</h1>
        <h6 className="gochi stale-grey mt-18 text-center">
          {eng_lang.admin_update_text}
        </h6>
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className="form-inputs"
        >
          <label htmlFor="username" className="text-white inter mt-46">
            Old Password
          </label>
          <input
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder="Please Enter Old Password"
            className="mt-4"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword ? (
            <p className="mt-4 error-msg-forms">{formik.errors.oldPassword}</p>
          ) : null}
          <label htmlFor="New Password" className="text-white inter mt-24">
            New Password
          </label>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="Please Enter New Password"
            className="mt-4"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <p className="mt-4 error-msg-forms">{formik.errors.newPassword}</p>
          ) : null}
          <label htmlFor="Confirm Password" className="text-white inter mt-24">
            Confirm Password
          </label>
          <input
            id="conPassword"
            name="conPassword"
            type="password"
            placeholder="Please Confirm New Password"
            className="mt-4"
            value={formik.values.conPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.conPassword && formik.errors.conPassword ? (
            <p className="mt-4 error-msg-forms">{formik.errors.conPassword}</p>
          ) : (
            formik.touched.conPassword &&
            formik.values.conPassword !== formik.values.newPassword && (
              <p className="mt-4 error-msg-forms">
                {eng_lang.passwordUnmatch}{" "}
              </p>
            )
          )}
          <div className="btn-container login-btn-container position-relative align-self-center br-4 mt-56">
            <span className="mas mas-login ">
              {eng_lang.buttonConstants.submit_btn_text}
            </span>
            <button
              type="submit"
              className=" btn btn-primary login-btn align-self-center gochi"
              disabled={
                formik.errors.oldPassword ||
                formik.errors.conPassword ||
                formik.errors.newPassword ||
                formik.values.conPassword !== formik.values.newPassword
                  ? true
                  : btnLoading
              }
            >
              {eng_lang.buttonConstants.submit_btn_text}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminPasswordUpdate;
