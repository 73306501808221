// libraries
import { Link } from "react-router-dom";

// constants
import { eng_lang, routes } from "../../../../lib/utills/constants";
import BlockButton from "../../block-button";

// components
import { Form } from "react-bootstrap";

const AdminDrawer = ({
  avatar,
  handleModal,
  logout,
  handleToggle,
  totalMinted,
  toggle,
  adminAvatar,
  AdminStatus,
  handleReveal,
  winnerRevealed,
}) => {
  return (
    <>
      <div
        className="offcanvas offcanvas-start  justify-content-end"
        id="collapsibleNavId"
        tabIndex="-1"
      >
        <button
          type="button"
          className="btn-close text-reset align-self-end p-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header d-flex justify-content-center mb-2">
          <h1 className="text-white">
            <Link
              to={routes.ADMIN_HOME}
              className="text-white header-title"
              data-bs-dismiss="offcanvas"
            >
              {eng_lang.title}
            </Link>
          </h1>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex tabs">
            <ul className="flex-column nav w-100 ">
              <li className="nav-item no-border">
                {adminAvatar !== null && adminAvatar !== "" && (
                  <img
                    className="position-absolute admin-status"
                    src={AdminStatus}
                    alt="online"
                  />
                )}
                <div
                  className={`avatar-profile m-auto mb-2 ${
                    adminAvatar !== null && adminAvatar !== "" ? "br-50" : ""
                  }`}
                >
                  <img
                    src={
                      adminAvatar !== null && adminAvatar !== ""
                        ? adminAvatar
                        : avatar
                    }
                    alt="avatar"
                    className="avatar-admin"
                  />
                </div>
              </li>

              <li className="nav-item">
                {totalMinted >= eng_lang.totalNoOfMintToken ? (
                  <Form className="ms-16 mt-16 enable-form dropdown-basic-btn gochi text-white d-flex justify-content-between mw-90 align-items-end">
                    Reveal Story & Attributes
                    <Form.Check
                      type="switch"
                      id="custom-switch-mobile"
                      className="text-white"
                      as="input"
                      label={""}
                      onClick={handleToggle}
                      defaultChecked={toggle ? true : false}
                    />
                  </Form>
                ) : null}
              </li>
              <li className="nav-item">
                <Form className="winner-reveal-form position-relative ms-16 mt-16 enable-form dropdown-basic-btn gochi text-white d-flex justify-content-between mw-90 align-items-center">
                  Reveal Winners
                  <Form.Check
                      type="switch"
                      id="custom-switch"
                      className="text-white"
                      as="input"
                      onClick={handleReveal}
                      label={""}
                      disabled={
                        totalMinted >= eng_lang.totalNoOfMintToken
                          ? false
                          : true
                      }
                      checked={winnerRevealed}
                      feedback={
                        totalMinted < eng_lang.totalNoOfMintToken
                          ? eng_lang.groupSelectPage.revealWinnerErrorFeedback
                          : null
                      }
                      feedbackTooltip
                      readOnly
                    />
                </Form>
              </li>

              <li className="nav-item no-border">
                <BlockButton
                  showImg={false}
                  text={"Withdraw Amount"}
                  imgPath={""}
                  name={"Withdraw Amount"}
                  handleClick={handleModal}
                  transparent_btn={true}
                  data_bs_dismiss="offcanvas"
                  borderRadius
                />
              </li>
              <li className="nav-item no-border">
                <BlockButton
                  showImg={false}
                  text={"Logout"}
                  imgPath={""}
                  name={"Logout"}
                  handleClick={logout}
                  transparent_btn={true}
                  data_bs_dismiss="offcanvas"
                  borderRadius
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDrawer;
