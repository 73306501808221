import { useSelector } from "react-redux";


// constants
import { eng_lang } from "../../../lib/utills/constants";

const SelectedCatComponent = ({ chosenSuspect, userSign, disablebtn }) => {
  const {
    winnerReducer: { isWinner },
  } = useSelector((state) => state);
  return (
    <>
      <div className="d-flex justify-content-center justify-content-lg-start">
        <img
          className="selected-cat"
          src={chosenSuspect?.imagePath}
          alt="cat"
        />
   
      </div>

      <div className="d-flex justify-content-between metadata-nft">
        <p className="text-white">
          {eng_lang.claim_attribute_set.section_three.serial_number}
        </p>
        <p className="text-white">{chosenSuspect?.index}</p>
      </div>

      <div className="btn-container mint-btn-container d-flex justify-content-center position-relative mx-auto">
        <span className="mas mas-mint">
          {eng_lang.buttonConstants.mint_suspect}
        </span>
        <button
          className="btn btn-primary mint-btn "
          data-mdb-ripple-color="primary"
          onClick={() => userSign(chosenSuspect)}
          disabled={isWinner ? true : disablebtn}
        >
          {eng_lang.buttonConstants.mint_suspect}
        </button>
      </div>
    </>
  );
};

export default SelectedCatComponent;
