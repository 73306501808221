// libraries
import React from "react";

// constants
import { eng_lang } from "../../../../lib/utills/constants";

const SetPrizeBody = ({ data, prize, setPrize, balance }) => {
  return (
    <>
      <h2 className="text-white text-center">
        {eng_lang.set_prize_modal.set_prize}
      </h2>
      <div className="sm-modal__body-margin">
        <label htmlFor="GroupName" className="inter text-white">
          {eng_lang.set_prize_modal.selected_group}
        </label>
        <div className="mt-4 w-100 set-prize-input br-4 text-white d-flex justify-content-between">
          {data[0]?.groupName}
          <p className="text-white gochi">{data[0]?.groupSize}</p>
        </div>
        <label htmlFor="GroupName" className="inter text-white mt-20">
          {eng_lang.set_prize_modal.prize_amount}
        </label>
        <div className="position-relative">
          <input
            id="prizeAmount"
            name="prizeAmount"
            type={"number"}
            min={"0"}
            placeholder="Set Prize"
            className="mt-4 w-100 set-prize-input br-4 inter"
            value={prize}
            onChange={(event) => {
              // parseInt to remove first digit 0
              setPrize(Math.trunc(parseInt(event.target.value)));
            }}
          />
          <span className="text-white dollar-span">$</span>
        </div>
        <p className="inter sub-text mt-4">
          {eng_lang.set_prize_modal.assigned_amount}
          {data[0]?.totalGroupPrize
            ? data[0]?.totalGroupPrize?.toLocaleString()
            : "0"}
        </p>

        <div className="d-flex justify-content-center mt-40 ">
          <p className="inter grey balance-data">
            {eng_lang.set_prize_modal.available_balance}
            <span className="gochi text-white">
              ${prize ? (balance - prize)?.toLocaleString() : balance?.toLocaleString()}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default SetPrizeBody;
