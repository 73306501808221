// libraries
import React, { useContext, Fragment } from "react";
import { Parallax } from "react-parallax";

// components
import CrowdSimulator from "../crowd-simulator";

//constants
import { eng_lang } from "../../../lib/utills/constants";
import { landinPageProps } from "../../../pages/landing-page";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";

//styles
import "./index.scss";

// Assets
import {
  pixelated_cat,
  border,
  minted_bg,
  admin_avatar_2,
} from "../../../assets";

const MintedSection = () => {
  const { totalMinted, winnerData, winnerRevealed, isWinner } =
    useContext(landinPageProps);
  const { walletAddressTruncater } = useContext(EtheriumContext);
  return (
    <>
      {winnerRevealed === eng_lang.winner_announced &&
      isWinner === eng_lang.winner_announced ? (
        <div className="position-relative overflow-visible ">
          <div className="winner-reveal ">
            <div className="d-flex flex-column align-items-center winner-content ">
              <h2 className="solved-heading">
                {eng_lang.winnerRevealLandingPage.heading}
              </h2>
              <img
                className="blur-cat-pos"
                src={pixelated_cat}
                alt="pixelated cat"
              />
              <img src={border} alt="border" className="border" />
              <h3 className="detective-heading">
                {eng_lang.winnerRevealLandingPage.case_detective}
              </h3>
              {winnerData?.winners?.map((absoluteWinner, index) => {
                return (
                  <Fragment key={index}>
                    {absoluteWinner?.groupName === eng_lang.groupOne && (
                      <>
                        <div className="winner-profile">
                          <img
                            src={
                              absoluteWinner?.owner?.openSeaProfile
                                ?.avatarUrl || admin_avatar_2
                            }
                            alt="winner"
                          />
                        </div>
                        <p className="winner-name">
                          {absoluteWinner?.owner?.openSeaProfile?.userName
                            ? absoluteWinner?.owner?.openSeaProfile
                                ?.userName !== "Unnamed"
                              ? absoluteWinner?.owner?.openSeaProfile?.userName
                              : walletAddressTruncater(
                                  absoluteWinner?.owner?.ownerAddress
                                )
                            : walletAddressTruncater(
                                absoluteWinner?.owner?.ownerAddress
                              )}
                        </p>
                      </>
                    )}
                  </Fragment>
                );
              })}

              <p className="arial black">
                <b>&</b>
              </p>
              <p className="mt-16 inter charcoal text-bold text-capitalize">
                {winnerData?.winners?.length - 1} other{" "}
                {winnerData?.winners?.length > 2 ? "winners" : "winner"} solved
                mystery
              </p>

              <div className="position-relative whodunit-btn-container">
                <span className="mas moz-style mas-whodunit">
                  {eng_lang.buttonConstants.who_dun_it}
                </span>
                <button
                  className="btn whodunit-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#scrollModal"
                  data-bs-dismiss="modal"
                >
                  {eng_lang.buttonConstants.who_dun_it}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Parallax bgImage={minted_bg} strength={300}>
        <div
          id="minted"
          className={`${
            winnerRevealed === eng_lang.winner_announced &&
            isWinner === eng_lang.winner_announced
              ? "minted-section-winner flex-column"
              : ""
          }`}
        >
          <div
            className={`d-flex justify-content-center align-items-center w-100 mb-4em ${
              winnerRevealed === eng_lang.winner_announced &&
              isWinner === eng_lang.winner_announced
                ? "winner-mobile"
                : ""
            } `}
          >
            {winnerRevealed !== eng_lang.winner_announced ||
            isWinner !== eng_lang.winner_announced ? (
              <div className={`text-box`}>
                <h2 className="text-white text-capitalize">
                  {eng_lang.mintedSection.total_Minted}
                </h2>
                <p className="minted-text">
                  {totalMinted || 0}
                  <br />
                  <span className="remaining-minted">
                    {`${eng_lang.mintedSection.of} ${eng_lang.mintedSection.remaining_minted}`}
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        </div>

        <CrowdSimulator />
      </Parallax>
    </>
  );
};

export default MintedSection;
