// libraries
import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3/dist/web3.min";
import storage from "redux-persist/lib/storage";
import axios from "axios";

// components
import { notfiFail } from "../../lib/helper/toast";

// blockchain functions
import {
  connectToMetaMask,
  buyNftMetaMask,
  approveAmount,
  networkDetails,
  getAccountBalance,
  signTransaction,
  withDraw,
  getTokenBalance,
  getWithDrawalAddress,
  getPrice,
  convertFromWei,
  getAllowance,
  getOwnerAddress,
  transfer,
  timeConverter,
  getSupply,
  walletAddressTruncater,
  getBlankNfts,
} from "./functions";

// import { convertToWei } from "./functions";

// actions
import { btnLoadingAction } from "../../redux/actions/nfts";
import { setCongrats } from "../../redux/actions/buy-flow/buyInProgress";
// import { setCongrats } from "../../redux/actions/buy-flow/buyInProgress";

import {
  metaMaskWalletConnected,
  walletConnectedFail,
} from "../../redux/actions/blockchain";
import {
  buyInProgressAction,
  buyErrorAction,
  newNftMinted,
  buyErrorSolved,
  insufficientBalanceAction,
} from "../../redux/actions/buy-flow";
import {
  suspectBtnLoading,
  claimNftSuccess,
  // claimNftFail,
} from "../../redux/actions/claim-attributes";

// constants
import BLOCKCHAIN_INTERFACES from "../../lib/utills/constants/blockchain-interfaces";
import usdt_abi from "../../lib/utills/constants/blockchain-interfaces/usdt-abi";
// import {
//   API_BASE_URL,
//   CONTRACT_ADDRESS,
//   USDT_ADDRESS,
//   WALLET_NAME,
//   WEB3_PROVIDER_URL,
// } from "../../enviroments";
import { api_routes, eng_lang } from "../../lib/utills/constants";

export const EtheriumContext = createContext({});

const EtheriumProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [account, setaccount] = useState(
    window?.ethereum?.selectedAddress || null
  );
  const {
    metaMaskWalletReducer: { walletAddress },
    setAmountReducer: { amount },
  } = useSelector((state) => state);
  const web3 = new Web3(
    Web3.givenProvider || process.env.REACT_APP_WEB3_PROVIDER_URL
  );

  // detect account change event on metamask
  window?.ethereum?.on("accountsChanged", function (accounts) {
    setaccount(accounts[0]);
  });

  // initiate our web 3 with wallet address
  const tokenInstance = new web3.eth.Contract(
    BLOCKCHAIN_INTERFACES,
    process.env.REACT_APP_CONTRACT_ADDRESS
  );

  const usdtInstance = new web3.eth.Contract(
    usdt_abi,
    process.env.REACT_APP_USDT_ADDRESS
  );

  useEffect(() => {
    checkUserLogin(window?.ethereum?.selectedAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.ethereum]);

  useEffect(() => {
    checkUserLogin(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const checkUserLogin = async (account) => {
    if (account === null) {
      return;
    }
    if (account) {
      dispatch(metaMaskWalletConnected(account));
      return true;
    } else {
      dispatch(walletConnectedFail());
      storage.removeItem("persist:root");
      return false;
    }
  };

  const getCatPrice = async () => {
    const catPrice = await getPrice(tokenInstance);
    if (catPrice) {
      return convertFromWei(web3, catPrice, "mwei");
    }
  };

  const getTotalMintsBlockChain = async () => {
    const totalSupply = await getSupply(tokenInstance);
    if (totalSupply) {
      return Number(totalSupply);
    }
  };

  const getUSDBalance = async () => {
    const balance = await getTokenBalance(usdtInstance, walletAddress);
    if (balance) {
      return convertFromWei(web3, balance, "mwei");
    }
  };
  const getOwnerNFT = async (nftIndex) => {
    const address = await getOwnerAddress(tokenInstance, nftIndex);
    if (address) {
      return address;
    }
  };

  const getAdminWalletAddress = async () => {
    const adminAddress = await getWithDrawalAddress(tokenInstance);
    if (adminAddress) {
      return adminAddress;
    }
  };
  const getAdminAddress = async () => {
    const withDrawalAddress = await getWithDrawalAddress(tokenInstance);
    if (withDrawalAddress) {
      return withDrawalAddress;
    }
  };
  const getContractTokenBalance = async () => {
    const getBalance = await getTokenBalance(
      usdtInstance,
      process.env.REACT_APP_CONTRACT_ADDRESS
    );
    if (getBalance) {
      return convertFromWei(web3, getBalance, "mwei");
    }
  };

  // connect with metamask wallet
  const walletConnection = useCallback(async () => {
    try {
      dispatch(btnLoadingAction(true));
      const resp = await connectToMetaMask();
      if (resp?.length > 0) {
        dispatch(metaMaskWalletConnected(resp[0]));
      } else {
        dispatch(btnLoadingAction(false));
        dispatch(walletConnectedFail());
      }
    } catch (error) {
      dispatch(btnLoadingAction(false));
      dispatch(walletConnectedFail());
      notfiFail(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // user login and network details
  const userDetails = useCallback(async () => {
    let isUserLogin = false,
      networkName = null,
      accBalance = null,
      tokenBalance = null,
      allowance = 0;
    try {
      // step -> 0 : check etherum login id
      if (window?.ethereum?.selectedAddress === null) {
        notfiFail(eng_lang.user_not_login);

        return {
          isUserLogin,
          networkName,
          accBalance,
        };
      }

      // step -> 1 : check user is logged in with wallet
      isUserLogin = await checkUserLogin(window?.ethereum?.selectedAddress);

      // step -> 2 : user not login then show toast msg
      if (!isUserLogin) {
        notfiFail(eng_lang.user_not_login);
      }

      // step -> 3:  get network name
      networkName = await networkDetails(web3);

      // step -> 4 :  check if blockchain name is renkeby
      if (networkName !== process.env.REACT_APP_WALLET_NAME) {
        notfiFail(eng_lang.contract_type_msg);
      }

      //step -> 5 : get account balance
      accBalance = await getAccountBalance(
        web3,
        window?.ethereum?.selectedAddress
      );
      tokenBalance = await getTokenBalance(usdtInstance, walletAddress);

      allowance = await getAllowance(
        usdtInstance,
        walletAddress,
        process.env.REACT_APP_CONTRACT_ADDRESS
      );
    } catch (error) {
      console.log("userDetails error:", error);
    }

    return {
      isUserLogin,
      networkName,
      accBalance,
      tokenBalance,
      allowance,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3]);

  const buyNft = useCallback(
    async (header) => {
      try {
        // step -> 1 : diable buy button

        dispatch(buyInProgressAction(true));

        // step -> 2 : check user is logged in and network name

        // accbalance will be pass for isufficient balance check here
        const { isUserLogin, networkName, tokenBalance, allowance } =
          await userDetails();

        // step -> 3 :  if network name is not rinkeby or user not connected to metamask then return
        if (networkName !== process.env.REACT_APP_WALLET_NAME || !isUserLogin) {
          dispatch(buyInProgressAction(false));
          return;
        }

        // for contract token amount value
        const mulValue = amount * 200;
        if (tokenBalance) {
          const usdTokens = convertFromWei(web3, tokenBalance, "mwei");
          //step -> 4 : if account balance less than nft price than return
          if (usdTokens < mulValue) {
            dispatch(insufficientBalanceAction(usdTokens, mulValue));
            return;
          }
        }

        //step -> 5 : get approval and then buy nft
        if (allowance < web3.utils.toWei(mulValue.toString(), "mwei")) {
          const approval = await approveAmount(
            usdtInstance,
            process.env.REACT_APP_CONTRACT_ADDRESS,
            web3.utils.toWei(mulValue.toString(), "mwei"),
            walletAddress
          );
          if (!approval) {
            console.log("approval error");
          }
        }

        const resp = await buyNftMetaMask(tokenInstance, walletAddress, amount);

        if (resp) {
          // step -> 6 : call new nft mint on success response

          dispatch(newNftMinted(resp));

          const blockchainMintedNftCount = await getTotalMintsBlockChain(
            tokenInstance
          );

          if (resp) {
            var receipt = await web3.eth.getTransactionReceipt(
              resp?.transactionHash
            );
            if (receipt) {
              var block = await web3.eth.getBlock(receipt.blockNumber);
              if (block) {
                // const addTransaction = await axios.post(
                //   `${process.env.REACT_APP_API_BASE_URL}/${api_routes.ADD_TRANSFER_RECORD}`,
                //   {
                //     transactionId: resp?.transactionHash,
                //     to: walletAddress,
                //     from: process.env.REACT_APP_CONTRACT_ADDRESS,
                //     amount: mulValue,
                //     transactionType: "Mint",
                //     transactionTime: block?.timestamp,
                //     minterAddress: walletAddress,
                //     totalCatsMinted: amount,
                //   },
                //   { headers: header }
                // );
                dispatch(buyErrorSolved());
                dispatch(setCongrats());
                // if (addTransaction) {
                blockchainMintedNftCount >= eng_lang.totalNoOfMintToken &&
                  (await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/${api_routes.NOTIFY_ADMIN}`
                  ));
                // }
              }
            }
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch(buyErrorAction(error));
        notfiFail(error?.message);
        dispatch(buyInProgressAction(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3]
  );

  const personalSign = async (claimNft) => {
    try {
      // step -> 1 : diable buy button
      dispatch(suspectBtnLoading(true));

      // step -> 2 : Check User Details
      const { isUserLogin, networkName } = await userDetails();
      let loginResponse;
      if (!isUserLogin) {
        loginResponse = await walletConnection();
      }
      if (!isUserLogin && loginResponse) {
        const { networkName } = await userDetails();
        // step -> 3 :  if network name is not goerli or user not connected to metamask then return
        if (networkName !== process.env.REACT_APP_WALLET_NAME) {
          dispatch(suspectBtnLoading(false));
          return;
        }
      }
      if (walletAddress === null) {
        dispatch(suspectBtnLoading(false));
        return;
      }

      // step -> 3 :  if network name is not rinkeyby or user not connected to metamask then return
      if (isUserLogin && networkName !== process.env.REACT_APP_WALLET_NAME) {
        dispatch(suspectBtnLoading(false));
        return;
      }

      const getBlankNftsAmount = await getBlankNfts(walletAddress);

      if (getBlankNftsAmount) {
        const blankNfts = getBlankNftsAmount?.availableMintedNft;
        console.log(blankNfts);
        const signature = await signTransaction(
          tokenInstance,
          claimNft,
          blankNfts,
          walletAddress
        );

        if (signature) {
          dispatch(claimNftSuccess(blankNfts, "success"));
        }
      } else {
        dispatch(suspectBtnLoading(false));
      }
    } catch (error) {
      console.log("personalSign error", error);
      if (error?.response?.status !== 400) {
        notfiFail(error?.message);
      }
      dispatch(suspectBtnLoading(false));
    }
  };

  const withDrawMetamask = async (adminAddress, balance, header) => {
    try {
      // change modals

      dispatch(buyInProgressAction(true));
      // get wallet details

      const { isUserLogin, networkName } = await userDetails();

      // step -> 3 :  if network name is not rinkeby or user not connected to metamask then return
      if (networkName !== process.env.REACT_APP_WALLET_NAME || !isUserLogin) {
        dispatch(buyInProgressAction(false));
        return;
      }
      // withdraw amount
      const resp = await withDraw(tokenInstance, walletAddress);
      if (resp) {
        dispatch(buyErrorSolved());
        dispatch(setCongrats());
        var receipt = await web3.eth.getTransactionReceipt(
          resp?.transactionHash
        );
        if (receipt) {
          await web3.eth.getBlock(receipt.blockNumber);
          // if (block) {
          //   await axios.post(
          //     `${process.env.REACT_APP_API_BASE_URL}/${api_routes.ADD_TRANSFER_RECORD}`,
          //     {
          //       transactionId: resp?.transactionHash,
          //       to: adminAddress,
          //       from: process.env.REACT_APP_CONTRACT_ADDRESS,
          //       amount: balance,
          //       transactionType: "Withdraw",
          //       transactionTime: block?.timestamp,
          //     },
          //     { headers: header }
          //   );
          // }
        }
      }
    } catch (error) {
      console.log("error", error);
      dispatch(buyErrorAction(error));
      notfiFail(error?.message);
      dispatch(buyInProgressAction(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const transferPrize = async (
    prize,
    owner,
    chosenSuspect,
    header,
    groupName,
    ownerName,
    ownerAvatar
  ) => {
    try {
      // change modal
      dispatch(buyInProgressAction(true));
      // get wallet details

      const { isUserLogin, networkName } = await userDetails();

      // step -> 3 :  if network name is not rinkeby or user not connected to metamask then return
      if (networkName !== process.env.REACT_APP_WALLET_NAME || !isUserLogin) {
        dispatch(buyInProgressAction(false));
        return;
      }

      // transfer amount
      const resp = await transfer(
        usdtInstance,
        walletAddress,
        web3.utils.toWei(prize?.toString(), "mwei"),
        owner
      );
      if (resp) {
        var receipt = await web3.eth.getTransactionReceipt(
          resp?.transactionHash
        );
        if (receipt) {
          var block = await web3.eth.getBlock(receipt.blockNumber);
          if (block) {
            dispatch(buyErrorSolved());
            dispatch(setCongrats());
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/${api_routes.ADD_TRANSFER_RECORD}`,
              {
                transactionId: resp?.transactionHash,
                to: owner,
                owner: {
                  ownerName: ownerName,
                  ownerAvatar: ownerAvatar,
                  ownerAddress: owner,
                },

                from: walletAddress,
                amount: prize,
                transactionType: "Prize-Transfer",
                catId: chosenSuspect?._id,
                groupName: groupName,
                transactionTime: block?.timestamp,
              },
              { headers: header }
            );
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      dispatch(buyErrorAction(error));
      notfiFail(error?.message);
      dispatch(buyInProgressAction(false));

      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/${api_routes.UPDATE_FAILED_TRANSFER_STATUS}`,
          {
            id: chosenSuspect?._id,
            prizeTransferStatus: "Failed",
          },
          { headers: header }
        )
        .catch((error) => console.log(error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <EtheriumContext.Provider
      value={{
        walletConnection,
        buyNft,
        personalSign,
        withDrawMetamask,
        getCatPrice,
        getAdminAddress,
        getContractTokenBalance,
        getAdminWalletAddress,
        getUSDBalance,
        getOwnerNFT,
        transferPrize,
        timeConverter,
        walletAddressTruncater,
      }}
    >
      {children}
    </EtheriumContext.Provider>
  );
};

export default EtheriumProvider;
