const buyNftMetaMask = (tokenInstance, walletAddress, amount) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .purchaseCat(walletAddress, amount)
      .send({
        from: walletAddress,
      })
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default buyNftMetaMask;
