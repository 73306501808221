// libraries
import React from "react";

// components

// constants
import { eng_lang } from "../../../../../../lib/utills/constants";
// import { OPEN_SEA_URL } from "../../../../../../enviroments";

// assets
import { Undeline } from "../../../../../../assets";

const TransferBody = ({
  chosenSuspect,
  buyerName,
  balance,
  prize,
  selectedCatGroup,
  owner,
}) => {
  return (
    <>
      <h2 className="text-white text-center">
        {eng_lang.transfer_prize_modal.title}
      </h2>
      <div className="sm-modal__body-margin">
        <div className="d-flex justify-content-between align-items-baseline">
          <p className="text-white">
            {eng_lang.transfer_prize_modal.prize_amount}
          </p>
          <h6 className="text-white fs-16 lh-19">
            {eng_lang.dollar}
            {selectedCatGroup === eng_lang.groupOne
              ? prize[0]?.toLocaleString() || 0
              : selectedCatGroup === eng_lang.groupTwo
              ? prize[1]?.toLocaleString() || 0
              : selectedCatGroup === eng_lang.groupThree
              ? prize[2]?.toLocaleString() || 0
              : null}
          </h6>
        </div>
        <div className="d-flex justify-content-between mt-13 align-items-baseline">
          <p className="text-white">
            {eng_lang.transfer_prize_modal.buyer_name}
          </p>
          <h6 className="text-white fs-16 lh-19 ">
            {buyerName === "Unnamed" || buyerName === "" ? "N/A" : buyerName}{" "}
          </h6>
        </div>
        <div className="d-flex justify-content-between mt-13 align-items-baseline">
          <p className="text-white">
            {eng_lang.transfer_prize_modal.reciever_address}
          </p>
          <h6 className="text-white fs-16 lh-19 text-break owner-address">
            {owner}{" "}
          </h6>
        </div>
        <div className="d-flex justify-content-between mt-13 align-items-baseline">
          <p className="text-white">
            {eng_lang.transfer_prize_modal.opensea_link}
          </p>
          <h6 className="fs-16 lh-19">
            <a
              href={`${process.env.REACT_APP_OPEN_SEA_URL}${chosenSuspect?.nft}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue gochi"
            >
              {eng_lang.groupSelectPage.click_here}
            </a>
          </h6>
        </div>

        <img src={Undeline} alt="Undeline" className="w-100 mt-24" />

        <h4 className="text-white text-center mt-24 gochi">
          {eng_lang.transfer_prize_modal.killer_attributes}
        </h4>
        <div className="d-flex justify-content-between align-items-baseline mt-13">
          <p className="text-white"> {eng_lang.transfer_prize_modal.hat}</p>
          <div className="d-flex flex-column align-items-end">
            <h6 className="text-white fs-16 lh-19">{chosenSuspect?.hat}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline mt-13">
          <p className="text-white">{eng_lang.transfer_prize_modal.clothes}</p>
          <div className="d-flex flex-column align-items-end">
            <h6 className="text-white fs-16 lh-19">
              {chosenSuspect?.clothing}
            </h6>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline mt-13">
          <p className="text-white"> {eng_lang.transfer_prize_modal.eyes}</p>
          <div className="d-flex flex-column align-items-end">
            <h6 className="text-white fs-16 lh-19">{chosenSuspect?.eyes}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline mt-13">
          <p className="text-white"> {eng_lang.transfer_prize_modal.height}</p>
          <div className="d-flex flex-column align-items-end">
            <h6 className="text-white fs-16 lh-19">{chosenSuspect?.height}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-baseline mt-13">
          <p className="text-white"> {eng_lang.transfer_prize_modal.skin}</p>
          <div className="d-flex flex-column align-items-end">
            <h6 className="text-white fs-16 lh-19">{chosenSuspect?.skin}</h6>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-40 ">
          <p className="inter grey balance-data">
            {eng_lang.set_prize_modal.available_balance}
            <span className="gochi text-white">${Number(balance)?.toLocaleString()}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default TransferBody;
