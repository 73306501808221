// axios methods
import { getRequest } from "../../../services/axiosMethod";

// action types
import { types } from "../../types";

// constants
import { api_routes } from "../../../lib/utills/constants";

const getToggleValueAction = () => {
  return async function (dispatch) {
    try {
      // api call to get total tokent minted
      let response = await getRequest(api_routes.GET_TOGGLE);
      if (response) dispatch(getToggleSuccess(response.data.toggleSetAttributesAndStoryPage));
    } catch (err) {
      dispatch(getToggleFailure());
    }
  };
};

const getToggleSuccess = (toggleValue) => {
  return function (dispatch) {
    dispatch({
      type: types.GET_TOGGLE_USER,
      payload: toggleValue,
    });
  };
};

const getToggleFailure = () => {
  return function (dispatch) {
    dispatch({
      type: types.TOGGLE_FAIL,
      payload: false,
    });
  };
};

export default getToggleValueAction;
