// libraries
import React, { useContext } from "react";

// components
import { BlockButton } from "../../commons";
import OtherWinnersSection from "../other-winners-section";

// style
import "./style.scss";

// constants
import { landinPageProps } from "../../../pages/landing-page";

// assets
import { ClipperPng, admin_avatar_2 } from "../../../assets";
import { eng_lang } from "../../../lib/utills/constants";

const ScrollingModalWinners = () => {
  const { winnerRevealed } = useContext(landinPageProps);
  return (
    <>
      {winnerRevealed && (
        <div
          className={`modal fade scroll-modal`}
          id="scrollModalOtherWinners"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable scroll-modal__modal-margin winner-wide">
            <div className="d-flex justify-content-center sm-modal__img-top">
              <img
                src={ClipperPng}
                alt={"success_1"}
                className={`sm-modal__cat-img `}
              />
            </div>
            <div className="modal-content modal__box">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="modal__btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {}}
                ></button>
              </div>
              <div className="modal-body scroll-modal__padding-body2 pb-0">
                <div className="d-flex  justify-content-center winner-header">
                  <h2 className="text-white"> Winners List </h2>
                </div>
                <OtherWinnersSection winner_avatar={admin_avatar_2} />
              </div>
              <div className="mt-20 mb-48 scroll-modal__padding-body2">
                <BlockButton
                  showImg={false}
                  imgPath={""}
                  text={eng_lang.buttonConstants.go_back}
                  data_bs_toggle="modal"
                  data_bs_target="#scrollModal"
                  data_bs_dismiss="modal"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollingModalWinners;
