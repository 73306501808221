// libraries
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//constants
import { eng_lang, routes } from "../../../lib/utills/constants";

// styles
import "./index.scss";
import Drawer from "./mobile-drawer";

const Header = ({ scroll }) => {
  const url = window.location.pathname;
  const {
    nftsReducer: { toggleAccessUser, totalMinted },
    winnerReducer: { winnerRevealed, isWinner },
  } = useSelector((state) => state);

  return (
    <div className="container-xl">
      <div
        className={` d-flex justify-content-end align-items-center fixed-top navbar navbar-dark navbar-light navbar-expand-lg ${
          scroll && "bg-dark "
        }`}
      >
        <button
          className={`navbar-toggler align-self-center justify-content-start me-5  ${
            scroll && "mt-0 align-self-start"
          }`}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#collapsibleNavId"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`header-position ${scroll && "mt-0 scroll-animation"}`}>
          <h1
            className={`text-white header__heading  ${
              scroll && "header__scroll-heading "
            }`}
          >
            <a href={routes.HOME} className="text-white header-title">
              {eng_lang.title}
            </a>
          </h1>
          <div className="d-flex tabs">
            <ul className="navbar-nav w-100">
              <li className="nav-item">
                <a
                  href={`${
                    url === routes.HOME ? "#howItWorks" : `${routes.HOME}`
                  }`}
                  className={`text-white nav-link`}
                >
                  {eng_lang.header.tabs[0]}
                </a>
              </li>
              {toggleAccessUser && totalMinted >= eng_lang.totalNoOfMintToken ? (
                <>
                  <li className={`nav-item `}>
                    <Link
                      to={routes.STORY_INTRO}
                      className={`text-white nav-link  ${
                        url === routes.STORY_INTRO || url === routes.CLUES
                          ? "text-decoration-underline"
                          : "text-decoration-none"
                      }`}
                    >
                      {eng_lang.header.tabs[1]}
                    </Link>
                  </li>
                  { winnerRevealed !== eng_lang.winner_announced || isWinner !== eng_lang.winner_announced ? (
                    <>
                      <li className={`nav-item`}>
                        <Link
                          to={routes.CLAIM_ATTRIBUTE}
                          className={`text-white nav-link  ${
                            url === routes.CLAIM_ATTRIBUTE &&
                            "text-decoration-underline"
                          } `}
                        >
                          {eng_lang.header.tabs[2]}
                        </Link>
                      </li>
                    </>
                  ): null}
                </>
              ) : null}
            </ul>
            {/* mobile drawer */}
            <Drawer windowLocation={url} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
