// Routes Constants
export const routes = {
  HOME: "/",
  STORY_RELEASE: "/story-release",
  STORY_INTRO: "/mystery",
  CLUES: "/mystery-clues",
  LOGIN: "/login",
  CLAIM_ATTRIBUTE: "/claim-attribute",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  FAQS: "/faqs",
  WINNER_REVEAL: "/winner-reveal",
  ADMIN_LOGIN: "/admin-login",
  ADMIN_HOME: "/admin-home",
  ADMIN_UPDATE_PASSWORD: "/admin-update-password",
};

export const blc_func_name = {
  ETH_REQUEST_ACCOUNTS: "eth_requestAccounts",
  REDEEM: "redeem",
};

export const social_media_links = {
  instagram: "https://instagram.com/whodunitcats?igshid=NzNkNDdiOGI=",
  twitter: "https://twitter.com/whodunitcats?s=11&t=ADpe9XvnQ5JFDpFTKW3_Bg",
};

export const api_routes = {
  MINTED_COUNT: "nfts/count-minted",
  CLAIMED_COUNT: "nfts/count-claimed",
  NEW_NFT_MINTED: "nfts/mint",
  TOGGLE: "nfts/toggle-attributes-story",
  GET_TOGGLE: "nfts/get-toggle-attributes-story",
  NOTIFY_ADMIN: "nfts/notify-admin",
  ADD_USER_WALLET: "users/add-user-wallet",
  CREATE_VOUCHER: "vouchers",
  SUSPECT_CATS: "suspected-cats/all?page=",
  CLAIM_CAT: "suspected-cats/claim",
  UPDATE_FAILED_TRANSFER_STATUS: "suspected-cats/update-prizeTransfer-status",
  CLAIM_CANCEL: "suspected-cats/claim-cancel",
  WINNER_REVEAL: "suspected-cats/winners",
  AUTH_USER: "users/auth",
  GET_GROUPS: "/groups/all?page=",
  UPDATE_PRIZE: "groups/set-prize",
  ADD_TRANSFER_RECORD: "transactions",
  UPDATE_PASSWORD: "users/update-password",
  TOGGLE_WINNER_REVEAL: "nfts/reveal-winners",
  GET_TOGGLE_WINNER_REVEAL: "nfts/get-toggle-reveal-winners",
  GET_BLANK_NFT_ON_CLAIM: "suspected-cats/available-user-mintedNft",
};

// Landing Page Constants
export const eng_lang = {
  title: "WhoDunIt Cats?",
  buy_nft: "Buy Nft",
  eth: "ETH",
  dollar: "$",
  usd: "USD",
  congratulation: "Congratulations!",
  stay_updated: "Stay updated!",
  view_on_open_sea: "View NFT on Opensea",
  view_multiple_on_open_sea: "View NFTs on Opensea",
  try_again: "Try Again",
  transaction_failed: "Transaction Failed...",
  transaction_failed_evm: "Transaction has been reverted by the EVM",
  insufficient_fund: "Insufficient Funds! ",
  contract_type_msg: "Transaction can be done on Goerli network only!",
  insufficient_fund_msg: "Not enough balance to proceed transaction.",
  connect_to_metamask: "Connect  Metamask",
  user_not_login: "User not connected wallet. Please login again.",
  conncetToBuy: "conncetToBuy",
  buyModal: "buyModal",
  teransferPrizeModal: "teransferPrizeModal",
  confirmationModal: "confirmationModal",
  withDrawModal: "withDrawModal",
  setPrizeModal: "setPrizeModal",
  totalNoOfMintToken: 5000,
  totalNoOfClaimed: 5000,
  pageSize: 9,
  adminPageSize: 27,
  claim_nft: "nft claimed succesfully",
  dont_claim_nft: "nft claimed unsuccesfully",
  error_pg_button_text: "Go back to homepage",
  winner_announced: true,
  admin_login_text: "Please enter the username and password sent on your email",
  admin_update_text:
    "Update the password of your account by filling the form below.",
  cat_price: 200,
  groupOne: "Absolute winner",
  groupTwo: "Only the placard number is wrong",
  groupThree: "4 out of 5 attributes are correct",
  passwordUnmatch: "The new password does not match with confirm password!",

  buttonConstants: {
    coming_soon: "Coming Soon",
    join_hunt_text: "Join the hunt",
    subscribe_btn_text: "Subscribe to our Newsletter",
    read_story: "View Pictures",
    begin_hunt: "Begin Hunt",
    mint_suspect: "Mint Suspect",
    continue_to_mint: "Continue To Mint",
    view_on_open_sea: "View On Opensea",
    who_dun_it: "Show me WhodunIt",
    buy_on_open_sea: "Buy On Opensea Marketplace",
    admin_login_btn_text: "Login",
    submit_btn_text: "Submit",
    accept_btn_text: "Yes I Accept",
    decline_btn_text: "Sorry Decline",
    set_prize_text: "Set Prize",
    update_prize_text: "Update Prize",
    transfer_prize_text: "Transfer Prize",
    transfer_failed_text: "Failed",
    transfer_successful_text: "Transferred",
    show_other_winners: "Show 100 Other Winners",
    go_back: "Go Back",
    send_prize: "Send Prize",
    unclaimed: "Not Yet Claimed",
  },

  header: {
    heading: "WhoDunIt Cats?",
    tabs: ["How it works?", "The Mystery", "Set Traits"],
  },
  hero: {
    coming_soon_text:
      "Metacatopolis has been rocked by a high-profile murder and all 5,000 citizens are suspects! Scroll down to find out how you can earn $100,000 for catching the killer!",
    card_text:
      "Metacatopolis has been rocked by a high-profile murder and all 5,000 citizens are suspects! The evidence has been made public and you have a chance to solve the mystery. Can you identify the killer and claim the $100,000 reward?",
    text_after_mint_crossed_5000:
      "A dangerous killer is at large in Metacatopolis! Study the mystery carefully and customize your cat to look like who you think committed the crime! If successful, you will be rewarded handsomely.",
  },
  mintedSection: {
    total_Minted: "total minted",
    minted_tokens: "3016",
    of: "of",
    remaining_minted: "5000",
  },
  howItWorks: {
    heading: "How it works?",
    first_line:
      "WhoDunIt Cats is not your ordinary NFT collection – it is a fun and interactive way for people to",
    second_line:
      "own great art, solve a challenging mystery, customize NFTs, win cash prizes, and be a part of",
    third_line: "the WDIC community.",
    first: "First",
    next: "Next",
    then: "Then",
    lastly: "Lastly,",
    prizes: "PRIZES",
    first_prize_line: "is the public mint of 5,000 WhoDunIt Starter Cats.",
    next_prize_line: ", the murder mystery story and evidence are released.",
    third_prize_line:
      ", holders have 30 days to customize their Starter Cats to look like the cat they think",
    third_prize_line_2: "committed the crime.",
    prize_tiers: [
      "$100,000 for all 6 traits correct",
      "$5,000 for 5/6 traits correct",
      "$1,000 for 4/6 traits correct",
    ],
  },
  howItWorksModal: {
    title: ["The Start", "The Mystery", "The Prize"],
    first_title_content_paras: [
      "WhoDunIt Cats launches with the public sale of 5,000 Starter Cats. These Starter Cats will have zero traits. Everyone will start the contest on an even playing field with the same Starter Cat.",
      "Aspiring detectives can mint Starter Cats from the WhoDunIt Cats website for $200 in USD Coin by clicking “Join the hunt” on the homepage and connecting their MetaMask wallet. You will also need a bit of Ethereum for transaction (“gas”) fees to complete the minting process. Your Starter Cat(s) will be viewable on OpenSea after being purchased so those interested can offer to buy it to come into the game and you can sell it to exit the game.",
    ],
    second_title_content_paras: [
      "The day after all 5,000 Starter Cats are minted, WhoDunIt Cats will release the mystery! A page of the WhoDunIt Cats website titled “The Mystery” will be unlocked at xx:xx United States Eastern Standard Time (time to be determined by community). The mystery will contain the clues and evidence needed to find the killer!",
      "You will then have 30 days from the release of the mystery to figure out what traits the killer cat had and then add those traits to your Starter Cat. You want your cat to match the killer!",
      "To add traits to your Starter Cat, another page of the WhoDunIt Cats website titled “Set Traits” will become available. Use the filter tool on this page to find the suspect with the set of 6 traits you want. Then, select that suspect and choose “Claim traits!” That suspect’s set of 6 traits will be added to your Starter Cat so that you now have a UNIQUE WhoDunIt Cat NFT!",
      "Note, all traits will be claimed in sets of 6 - it will not be an option to claim just one trait at a time. A set of 6 traits will only be available to the first person to claim it, so act fast! Already taken sets of traits will show as “claimed” on the WhoDunIt Cats website.",
      "The updated cats with traits will be available on OpenSea once claimed, so there will be opportunities for trading. If someone else has already claimed the traits you want for their cat, try to acquire their cat from them on OpenSea before the deadline!",
    ],
    third_title_content_para: [
      "At the end of the 30 days, the killer cat’s identity will be revealed and the prizes listed below will be awarded for each WhoDunIt Cat in your wallet with at least 4 of the killer’s traits! The more of the killer’s traits that your WhoDunIt Cat has, the greater the prize!",
      "Join the mailing list to find out when WhoDunIt Cats go on sale, when they sell out, and when a winner is revealed!",
    ],
    last_title_descr:
      "Click this link to watch a video of how to mint and add traits to your cat!",
  },
  storyRelease: {
    heading: "Now the hunt begins",
  },
  storyUpdate: {
    intro_heading: "Dear Volunteer Detective,",
    first_line:
      "Thank you for your interest in this unsolved case. It is extremely rare that our firm of private investigators discloses the classified information of an ongoing investigation, but after months of having no leads, we thought it necessary to turn to the public.",
    second_line: "The case in question is a murder.",
    third_line:
      "The night of the incident, our investigators took three pictures, which have been declassified for you. We also rounded up all 5,000 residents of Metacatopolis and took a mug shot of each one. Review the evidence in the three pictures that follow this letter and then use the subsequent page to filter through the mugshots of all 5,000 suspects. When you think you’ve found the killer, select that suspect to add that their traits to your Starter Cat.",
    fouth_line:
      "Not a single suspect in Metacatopolis has been ruled out at this time.",
    fifth_line: "The rewards have been made known.",
    sixth_line: "Happy hunting.",
    seventh_line: "Director of Forensics, Metacatopolis",
  },
  footer: {
    faq: "FAQ",
    terms_and_conditions: "Terms and Conditions",
    support_email: "forensics@whodunitcats.com",
    stay_in_touch: "Stay in touch with us",
    copyrights: "©2022 WhoDunItCats. All Rights Reserved.",
    twitter: "Twitter",
    instagram: "Instagram",
    discord: "Discord",
  },
  joint_the_hunt_modal: {
    title: "Starter Cat",
    price: "Price",
    total_minted: "Total Minted",
    sub_title: "What you get & how to use",
    of: "of",
    paragraph:
      "Mint a Starter Cat to join the hunt! After all 5,000 are minted, go to whodunitcats.com to solve the murder mystery and add the killer’s traits to your Starter Cat to win big money! Read the full rules on the homepage!",
  },
  buy_flow_modal: {
    transaction_detail: "Transaction Details",
    nft_price: "NFT",
    no_of_cats: "Number of Cats",
    transaction_fee: "Transaction Fee",
    total_price: "Total Price",
    processing: "Processing...",
    processing_content: "Your transaction should only take a moment.",
    setting_prize_content: "Setting Prize, should only take a moment.",
    congratulation_content:
      "You have successfully obtained a WhoDunItCats NFT. The NFT has been transferred to your wallet.",
    trans_failed_content: "Eu sollicitudin risus nunc, scelerisque semper ac.",
  },
  set_prize_modal: {
    set_prize: "Set Prize",
    selected_group: "Selected Group",
    prize_amount: "Prize Amount (Per single winner)",
    assigned_amount: "Total amount that is assigned to this group : $",
    available_balance: "Available balance: ",
  },
  terms_and_conditions_modal: {
    title: "Terms and Conditions",
    first_para:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At congue sapien proin montes, odio leo, sit. Ut sem euismod risus ac pellentesque rutrum nec aliquet. Egestas fringilla tempus ut eu, gravida. Turpis amet porta nunc sed feugiat vel. Arcu bibendum ipsum ullamcorper tristique. Adipiscing sed lectus vitae faucibus in at tellus mauris morbi.",
    first_para_line_1: "Et vulputate fringilla odio aliquam. Sollicitudin",
    first_para_line_2:
      "cursus ligula accumsan pretium sapien, egestas volutpat.",

    second_para_line_1:
      "Diam lectus semper massa, ac pretium vitae. Dolor purus at facilisi nibh. Urna posuere id imperdiet sit lectus donec fermentum turpis sit. Volutpat, tortor erat arcu ligula duis a vel at.",
    second_para_line_2:
      "Ut pulvinar amet eu ultricies in. Purus adipiscing pharetra, sit lacus sem pellentesque id. Dis",
    second_para_line_3:
      "euismod egestas cras scelerisque habitasse turpis. Dignissim eget a eu magna vestibulum accumsan neque. Diam orci scelerisque elit turpis tellus.",
  },
  withdraw_modal: {
    title: "Withdraw Amount",
    balance: "Balance",
    contract_address: "Contract Address",
    reciever_address: "Receiver Address",
    withdraw_success: "Withdraw Successful!",
  },
  transfer_prize_modal: {
    title: "Send Prize",
    prize_amount: "Prize Amount",
    buyer_name: "Buyer Name",
    opensea_link: "Cat Opensea Link",
    hat: "Hat",
    clothes: "Clothing",
    eyes: "Eyes",
    height: "Height",
    skin: "Skin",
    killer_attributes: "Killer Attributes",
    reciever_address: "Buyer Address",
    withdraw_success: "Withdraw Successful!",
  },

  claim_attribute_set: {
    section_one: {
      title: "Select suspect traits",
      select_data: [
        {
          attribute_name: "Availability",
          element_name: "availability",
          options: ["All", "Available", "Unavailable"],
        },
        {
          attribute_name: "Height",
          element_name: "height",
          options: [
            "Select Height",
            "1'10''",
            "1'11''",
            "2'0''",
            "2'1''",
            "2'2''",
          ],
        },
        {
          attribute_name: "Eyes",
          element_name: "eyes",
          options: [
            "Select Eyes",
            "Blue Eyes",
            "Green Eyes",
            "Sunglasses",
            "Star Glasses",
          ],
        },
        {
          attribute_name: "Skin",
          element_name: "skin",
          options: ["Select Skin", "Furry", "Hairless"],
        },
        {
          attribute_name: "Hat",
          element_name: "hat",
          options: [
            "Select Hat",
            "Cowboy Hat",
            "Drink Hat",
            "Hard Hat",
            "Party Hat",
            "Propeller Hat",
          ],
        },
        {
          attribute_name: "Clothing",
          element_name: "clothing",
          options: [
            "Select Clothing",
            "Button Up Shirt",
            "Coat",
            "Fishing Vest",
            "Overalls",
            "T shirt",
          ],
        },
      ],
    },
    section_two: {
      title: "Choose your suspect",
      hover_heading: "Unavailable",
      hover_descr_1: "If you want to see who",
      hover_descr_2: "purchase it",
      unavailable_cats:
        "  We didn't find any cats. Make sure to choose different filters.",
    },
    section_three: {
      title: "Claim Suspect's Traits",
      serial_number: "Serial Number",
      serial_value: "1 -",
      plack_number: "Placard Number",
      plank_value: "152374",
    },
    congrats_text_modal:
      "Good work, detective! The set of traits you selected has been added to your WhoDunIt Starter Cat! Did you catch the killer??? We’ll find out soon…",
    error_heading: "Can’t choose killer",
    error_body: "Server Error",
  },
  story_clues: {
    paragrapgh_line_1: "The Crime Scene",
    paragrapgh_line_2: "The Victim’s Office",
    paragrapgh_line_3: "The Private Investigator’s HQ",
  },
  stories: [
    {
      paragrapgh_line_1:
        "Neque orci dui, sit libero diam magnis nunc et. Molestie diam gravida suspendisse hac aliquam elit. Et turpis placerat non turpis ",
      paragrapgh_line_2:
        "dui. Egestas elit nibh in eros dictum. Odio feugiat amet, diam eget cursus. Nec, vivamus arcu, amet leo aliquam.",
    },
    {
      paragrapgh_line_1:
        "Neque orci dui, sit libero diam magnis nunc et. Molestie diam gravida suspendisse hac aliquam elit. Et turpis placerat non turpis ",
      paragrapgh_line_2:
        "dui. Egestas elit nibh in eros dictum. Odio feugiat amet, diam eget cursus. Nec, vivamus arcu, amet leo aliquam.",
    },
    {
      paragrapgh_line_1:
        "Neque orci dui, sit libero diam magnis nunc et. Molestie diam gravida suspendisse hac aliquam elit. Et turpis placerat non turpis ",
      paragrapgh_line_2:
        "dui. Egestas elit nibh in eros dictum. Odio feugiat amet, diam eget cursus. Nec, vivamus arcu, amet leo aliquam.",
    },
  ],
  termsAndConditions: {
    heading1: "Terms and Conditions",
    subtitle: "Last Updated: October 6, 2022",
    paragraph_1:
      "WhoDunIt Cats is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to purchase digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the WhoDunIt Cats smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.",
    paragraph_2:
      "This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving WhoDunIt Cats digital collectibles.",
    paragraph_3:
      "You are solely responsible for your own conduct while accessing or using the Site, and for any consequences thereof. You agree to use the Site only for purposes that are legal, proper and in accordance with these Terms and any applicable laws or regulations. By way of example, and not as a limitation, you may not, and may not allow any third party to: (i) send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, fraudulent, hateful, violent, obscene, or otherwise objectionable content; (ii) distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature; (iii) impersonate another person; (iv) upload, post, transmit or otherwise make available through the Site any content that infringes the intellectual property or proprietary rights of any party or otherwise violates the legal rights of others; (v) engage in, promote, or encourage illegal activity (including, without limitation, money laundering); (vi) interfere with other users' use of the Site; (vii) use the Site for any unauthorized commercial purpose; (viii) modify, adapt, translate, or reverse engineer any portion of the Site; (ix) remove any copyright, trademark or other proprietary rights notices contained in or on the Site or any part of it; (x) use any technology to collect information about the Site’s for any unauthorized purpose; (xi) access or use the Site for the purpose of creating a product or service that is competitive with any of our products or services. If you engage in any of the activities prohibited by this Section, we may, at our sole and absolute discretion, without notice to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account.",
    paragraph_4:
      "You agree to hold harmless and indemnify Ocho Creative, LLC and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost, and reasonable attorneys' fees arising out of or in any way related to (i) your breach of these Terms, (ii) your misuse of the Site, or (iii) your violation of applicable laws, rules or regulations in connection with your access to or use of the Site.",
    paragraph_5:
      "We may make changes to the Terms at our discretion. Please check these Terms periodically for changes. Any changes to the Terms will apply on the date that they are made, and your continued access to or use after the Terms have been updated will constitute your binding acceptance of the updates. If you do not agree to any revised Terms, you may not access or use the Site.",
    paragraph_6:
      "Our Site is not intended for children or minors.  You must be at least 18 years old to access this Site or purchase a WhoDunIt Cat. If you are under 18 years old you are not permitted to use this Site for any reason. By accessing the Site, you represent and warrant that you are at least 18 years of age.",
    paragraph_7:
      "The WhoDunIt Cats NFT game is not intended to be played by contestants where such is prohibited by law.  Therefore, if you live in such a jurisdiction, you will not be eligible to receive a prize.  Ocho Creative, LLC shall have the authority to allocate your winnings to other contestants in the event you are unable to receive such prize per the laws governing your jurisdiction.",
    heading2: "1. Ownership",
    heading3: "2. Your Obligations",
    heading4: "3. Fees and Payment",
    heading5: "4. Disclaimers",
    heading6: "5. Limitation of Liability",
    heading7: "6. Risk Assumption",
    heading8: "7. Indemnification",
    heading9: "8. Changes to the Terms and Conditions",
    heading10: "9. Minors",
    heading11: "10. Jurisdiction",
    heading12:
      "11. Dispute Resolution; Mutual Agreement to Arbitrate; Class Action Waiver",
    acknowledge: "You accept and acknowledge each of the following:",
    headings_points: {
      heading2_point: [
        "You Own the NFT. Each WhoDunIt Cat is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying WhoDunIt Cat, the Art, completely. Ownership of the NFT is mediated entirely by the Ethereum Network Smart Contract and at no point may we seize, freeze, or otherwise modify the ownership of any WhoDunIt Cat.",
        "Personal Use.  Subject to your continued compliance with these Terms, Ocho Creative, LLC grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your WhoDunIt Cat / NFT, provided that the marketplace cryptographically verifies each WhoDunIt Cat owner’s rights to display the Art for their WhoDunIt Cat to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your WhoDunIt Cat, provided that the website/application cryptographically verifies each WhoDunIt Cat owner’s rights to display the Art for their WhoDunIt Cat to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the WhoDunIt Cat leaves the website/application.",
        "Commercial Use. Unless agreed upon in writing from Ocho Creative, LLC, owners shall not be able to use any WhoDunIt Cat for commercial purposes.  If Ocho Creative, LLC approves commercial use of a WhoDunIt Cat any such usage will be subject to your continued compliance with these Terms.  Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art.",
        "WhoDunItCats IP.  Other than the rights to the Art, nothing herein gives you any rights to any other trademarks or other intellectual property rights belonging to Ocho Creative, LLC.",
        "Feedback. You may choose to submit comments, bug reports, ideas or other feedback about the Site, including without limitation about how to improve the Site (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to use such Feedback in any way we choose without additional compensation to you and you hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license to incorporate and use the Feedback for any purpose.",
      ],
      heading4_point: [
        "If you elect to purchase a WhoDunIt Cat through the Site, any financial transactions that you engage in will be conducted solely through the Ethereum network. We will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions.  We will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage or any other transactions that you conduct via the Ethereum network.",
        "Ethereum requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Ethereum network. The Gas Fee funds the network of computers that run the decentralized Ethereum network. This means that you will need to pay a Gas Fee for each transaction.",
      ],
      heading5_point: [
        `YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE, (III) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.`,
        "YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT.",
        "WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK NOR DO WE HAVE ANY CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.",
      ],
      heading6_point: [
        "YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
        "YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $500.",
        "YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.",
      ],
      heading7_point: [
        "The rules of the WhoDunIt mystery have been provided to you on the WhoDunIt Cats website and you have read such rules prior to purchasing your NFT. The rules are incorporated as part of these Terms and Conditions.",
        "All 5,000 WhoDunIt Cats must be minted before Ocho Creative, LLC releases clues regarding the mystery.  Owners accept the risk that the collection may not sell out.  No prizes or refunds for minted WhoDunIt Cats will be provided if the full collection is not minted.",
        "To the extent that you sell your WhoDunIt Cat NFT, please be aware that the prices of NFTs are extremely volatile and fluctuations in the prices of other NFTs impact the price of your WhoDunIt Cat both positively and negatively.  Given the volatility, NFTs such as WhoDunIt Cat should not be considered an investment. You assume all risks in that connection.",
        "Ownership of a WhoDunIt Cat confers ownership of digital artwork only. Accordingly, no information on this Site (or any other documents mentioned therein) is or may be considered to be advice or an invitation to enter into an agreement for any investment purpose. Further, nothing on this Site qualifies or is intended to be an offering of securities in any jurisdiction nor does it constitute an offer or an invitation to purchase shares, securities or other financial products.  Due to the artistic nature of the project, WhoDunIt Cats has not been registered with or approved by any regulator in any jurisdiction. It remains your sole responsibility to assure that the purchase of the WhoDunIt Cat and the associated art is in compliance with laws and regulations in your jurisdiction.",
        "You assume all risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet.",
        "NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unsettled.  New regulations could negatively impact such technologies impacting the value for your WhoDunIt Cat. You understand and accept all risk in that regard.",
        "You assume all responsibility for any adverse effects of disruptions or other issues impacting Ethereum or the Ethereum platform.",
        "All owner wallets holding prize-winning NFT’s will be documented and posted on the WhoDunIt Cats website homepage.  Owners understand that this will be available for public viewing.",
        "Prize money will be sent directly to wallets holding prize-winning NFTs in USD Coin. Prize money cannot be sent to a wallet not holding a prize-winning NFT. Owners will be responsible for payment of any taxes due pursuant to prize-winnings.",
        "You must possess a prize-winning NFT at the end of the 30 day mystery-solving period in order to win prize money. If you claim a set of traits for their NFT cat that makes their NFT a prize-winning NFT after the 30 days is over, you will not receive prize money. Prize money will all be paid out on the 31st day after the mystery is made available based on the cats with traits owned by holders at that point in time. Therefore, a prize amount for a prize-winning set of traits will go unpaid if nobody claims that set of traits before the deadline.",
      ],
    },
    heading12_paras: [
      "All disputes arising out of or in connection with these Terms, including without limitation your access or use of the Site, or to any products sold or distributed through the Site, will be referred to and finally resolved by arbitration under the rules of the American Arbitration Association. The case will be adjudicated by a single arbitrator and will be administered by the American Arbitration Association in accordance with its applicable rules. Each party will cover its own fees and costs associated with the arbitration proceedings. Missouri state laws shall govern any disputes under this agreement.  The place of arbitration will be Kansas City, Missouri or other location agreed upon by the parties in writing. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing, Ocho Creative LLC may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction.",
      "To the full extent permitted by law, any claim by you or Ocho Creative LLC which is subject to arbitration under the terms of this Mutual Agreement to Arbitrate must be brought in the party’s individual capacity and not as a plaintiff, or class member or in any purported class, collective, representative, multiple plaintiffs or similar non-individual proceeding (collectively “Class Action”). You and Ocho Creative LLC expressly waive, to the full extent permitted by law, any and all rights to bring, participate in or maintain in any forum any Class Action regarding or raising claims which are subject to arbitration under the terms of this Mutual Agreement to Arbitrate. The arbitrator shall not have authority to combine or aggregate similar claims, or conduct any Class Action or make an award to any person or entity not a party to the arbitration.",
      "Any claim that all or part of the Class Action Waiver is invalid, unenforceable, contrary to public policy or any law, or inapplicable to a claim brought in other than your or the relevant entity’s individual capacity, may be determined only by a court of competent jurisdiction and not by an arbitrator.",
      "WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SITE, OR THE SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.",
    ],
  },
  winnerRevealLandingPage: {
    heading: "MYSTERY SOLVED!",
    cat_no: "Cat#0",
    // cat_name: "Cat Name",
    plack_number: "Placard Number",
    eyes: "Eyes",
    skin: "Skin",
    height: "Height",
    hat: "Hat",
    clothing: "Clothing",
    congrat: "Congratulations to",
    worldWide: "MrWorldWideWeb",
    mystery_text: "For solving the mystery and winning",
    bounty: "bounty!",
    case_deduction: "Case Deduction",
    case_line_1:
      "Magna sapien tincidunt felis felis. Quis ullamcorper ac tellus lorem lorem",
    case_line_2:
      "posuere egestas. Imperdiet habitant amet nulla cursus purus nulla.",
    case_line_3:
      "Tincidunt ornare enim elit ante dui vulputate. Massa sodales porttitor eu",
    case_lines:
      "at. Ornare adipiscing viverra phasellus velit sapien. Diam est vitae accumsan lobortis erat vulputate sit varius convallis. Vel eget in luctus parturient sit cum. Gravida placerat ac leo tellus, urna dolor posuere aenean. Amet lacus, nisl nisl enim, enim, integer augue dui. At a, sagittis lobortis netus turpis. In nec hendrerit ac, tincidunt ut. Integer volutpat commodo convallis congue tincidunt. Sit lectus vitae pellentesque in. Ac pulvinar rutrum pellentesque amet varius nec. Faucibus nunc convallis bibendum tortor urna feugiat odio mus eget. Cursus magna facilisi malesuada vestibulum sollicitudin sed eget ac. Enim molestie nibh netus ridiculus. Blandit sodales blandit orci purus est blandit. Nec et non sed elementum imperdiet turpis ultricies turpis aliquet.",
    case_detective: "CASE DETECTIVE",
    winner_name: "MrWorldWideWeb",
  },

  FaqPage: {
    heading: "faq",
    questions_answers: [
      {
        question: "What is WhoDunIt Cats?",
        answer:
          "An NFT mystery game! Mint a cat, solve the mystery, and customize your cat to win big money!",
      },
      {
        question: "How do I join the hunt?",
        answer:
          "You will be able to join the WhoDunIt Cats community and compete for the prize money by minting a Starter Cat from our website. Follow us on social media (links at the bottom of the page) to find out when!",
      },
      {
        question: "Do I need a MetaMask wallet?",
        answer:
          "Yes, you will need a Metamask wallet to mint a Starter Cat from our website! Go to https://metamask.io/ and download to start the process!",
      },
      {
        question: "How do I get USD Coin and Ethereum?",
        answer: `Below are links to helpful sources for each step of the process! If this is all new to you, don’t worry - it’s easy! Welcome to the world of blockchain technology!
          To add USD Coin as a token in your MetaMask wallet (Ethereum is automatically added by default): https://www.followchain.org/add-usdc-metamask/
          To buy USD Coin or Ethereum, you’ll want to make an account with a crypto exchange. Coinbase is a fast and easy platform that offers Ethereum and USDC: https://www.coinbase.com/
          To transfer USDC or Ethereum to your Metamask wallet from Coinbase: https://cryptonews.com/guides/how-to-send-ethereum-from-coinbase-to-metamask-wallet.htm`,
      },
      {
        question: "How many cats can I mint?",
        answer:
          "As many as you want!  For every cat you mint, you will be able to claim a different set of traits from the 5,000 combinations available. It just depends on how good you are at being a detective. One Starter Cat gets you a seat at the table. Two or more gives you more chances.",
      },
      {
        question: "When and where will evidence be released?",
        answer:
          "The entire mystery and all evidence to be used in solving it will be released all at once onto the WhoDunIt Cats website in a page called “The Mystery”. This will happen on the day after all 5,000 Starter Cats are minted at the time chosen by the community. No information found anywhere else, including on the home page, in advertisements, emails, or social media posts, will be relevant to solving the WhoDunIt Cats mystery.",
      },
      {
        question:
          "How will I know how much time is left to solve the mystery and customize my cat?",
        answer:
          "Follow WhoDunIt Cats on social media and subscribe to the email newsletter to receive updates on time remaining to solve the mystery!",
      },
      {
        question:
          "How much Ethereum will I need for gas fees to mint and customize a WhoDunIt Cat?",
        answer: `The gas price may vary, but on November 16, 2022, these would be the following amounts:
        To Buy 1 Cat :  0.001308285 Eth = 1.57604 USD
        To Buy 2 Cats :  0.00133728 Eth = 1.60763 USD
        To Buy 3 Cats :  0.001366275 Eth = 1.64753 USD
        To Claim (add) Traits:  0.001119615 Eth = 1.3498 USD
        The fee report above is only valid for gas fees of 15Gwei.
        `,
      },
      {
        question: "Where will I be able to view my NFTs?",
        answer:
          "OpenSea! All WhoDunIt Cats NFTs, including those without traits and those with traits already added, will be visible on OpenSea. Log into your OpenSea account to view your collection.",
      },
      {
        question: "How many people know the identity of the murderer cat?",
        answer:
          "Only the founder of the WhoDunIt Cats knows the solution to the mystery. Even the artists and developers who helped bring WhoDunIt Cats to life do not know the solution. All collaborators and their friends and family members are legally prohibited from participating or helping anyone participate in the mystery contest in any way.",
      },
      {
        question: "Will the solution be explained in the end?",
        answer:
          "Yes! At the end of the 30-day period allotted for solving the mystery, the solution to the mystery will be posted on the homepage!",
      },
      {
        question: "How will prize money be paid?",
        answer:
          "Prize money will be sent in USDC to the wallets holding winning NFTs.",
      },
      {
        question: "Will the winners be publicly announced?",
        answer:
          "The accounts of those holding winning NFTs will be posted to the site after the contest ends. No personal information will be shared.",
      },
      {
        question: "Why is WhoDunIt Cats using USDC?",
        answer:
          "We had the choice between Ethereum and USD Coin, and we chose USD Coin to better protect your investment!  USDC is a stable coin whose value is always equal to the value of the US dollar. This will provide a secure currency for our community by eliminating risk and keeping more money in holder’s pockets!",
      },
      {
        question: "What blockchain is WhoDunIt Cats on?",
        answer:
          "Ethereum. Because of this, holders will need a bit of Ethereum to pay gas fees when minting their cat.",
      },
      // {
      //   question:
      //     "Where can I see the WhoDunIt Cats murder mystery?",
      //   answer:
      //     "The mystery will be posted to a page titled “The Mystery” of this website (whodunitcats.com). This page will not become available until all 5,000 Starter Cats are minted.",
      // },
      // {
      //   question:
      //     "Where do I go to customize my Starter Cat?",
      //   answer:
      //     "Owners will be able to customize their cats in a page called “Set Traits” of this website (whodunitcats.com). This page will not become available until all 5,000 Starter Cats are minted.",
      // },
    ],
  },

  groupSelectPage: {
    select_killer_group: "Select the killer group",
    main_winner_heading: "Absolute winner",
    groupTwo_heading: "Only the placard number is wrong",
    groupThree_heading: "4 out of 5 attributes are correct",
    single_reward: "Single Reward: ",
    prize_transferred: "Transferred",
    prize_transfer_failed: "Failed",
    serial_number: "Serial Number",
    buyer_name: "Buyer Name",
    open_sea_link: "Open Sea Link",
    click_here: "Click Here",
    plack_number: "Placard Number",
    hat: "Hat",
    skin: "skin",
    clothes: "Clothing",
    eyes: "Eyes",
    height: "Height",
    subGroupEyes: "Only eyes are wrong",
    subGroupHeight: "Only height is wrong",
    subGroupSkin: "Only skin is wrong",
    subGroupHat: "Only hat is wrong",
    subGroupClothing: "",
    revealWinnerErrorFeedback:
      "You can only reveal the winner when 5000 starter cats are minted successfully; at least 2 winners have claimed their cats, and you have transferred prize to them.(Absolute winner and one other winner).",

    group_select_data: [
      {
        attribute_name: "Select the killer group",
        element_name: "groupName",
        options: [
          "All Groups",
          "Absolute winner",
          "Only the placard number is wrong",
          "4 out of 5 attributes are correct",
        ],
      },
    ],
    sub_group_select_data: [
      {
        attribute_name: "Select sub-group",
        element_name: "subGroupName",
        options: [
          "All sub-groups",
          "Only hat is wrong",
          "Only clothing is wrong",
          "Only eyes are wrong",
          "Only height is wrong",
          "Only skin is wrong",
        ],
      },
    ],
  },
};
