// libraries
import React, { useContext, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";

//constants
import { eng_lang, routes } from "../../../lib/utills/constants";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";

// redux actions
import { showModalAction } from "../../../redux/actions/nfts";
import { authFail } from "../../../redux/actions/auth-user/authentication";
import { getToggleAccessAction } from "../../../redux/actions/toggle-access";
import { toggleWinnerRevealAction } from "../../../redux/actions/winner-reveal";

// styles
import "./style.scss";

// components
import AdminDrawer from "./mobile-drawer";
import { BlockButton } from "..";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

// helper component
import Toast from "../../../lib/helper/toast";

// assets
import { admin_avatar, Admin_status } from "../../../assets";

const AdminHeader = ({ scroll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    nftsReducer: { totalMinted, toggleAccessUser },
    authReducer: { accessToken },
    winnerReducer: { winnerRevealed },
  } = useSelector((state) => state);

  const [avatar, setAvatar] = useState(null);
  const [adminAddress, setAdminAddress] = useState(null);

  const { getAdminAddress } = useContext(EtheriumContext);

  // bypass limit of Opensea API by retrying
  axiosRetry(axios, {
    retries: 10, // number of retries
    retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      // return retryCount * 2000; // time interval between retries
      return 2000;
    },
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return error.response.status === 429;
    },
  });

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const handleClick = () => {
    dispatch(showModalAction(eng_lang.withDrawModal));
  };

  const handleToggle = () => {
    dispatch(getToggleAccessAction(header));
  };

  const handleReveal = () => {
    dispatch(toggleWinnerRevealAction(header));
  };

  const logout = () => {
    dispatch(authFail());
    navigate(routes.ADMIN_LOGIN);
  };

  useEffect(() => {
    getAdminAddress()
      .then((address) => setAdminAddress(address))
      .catch((error) => console.log(error));
  }, [getAdminAddress]);

  const getWinnerOpenSeaProfile = useCallback(async () => {
    try {
      if (adminAddress !== null) {
        const resp = await axios.get(
          `https://testnets-api.opensea.io/api/v1/account/${adminAddress}`
        );

        if (resp) {
          return resp;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [adminAddress]);

  useEffect(() => {
    if (adminAddress !== null) {
      getWinnerOpenSeaProfile()
        .then((res) => setAvatar(res?.data?.data?.profile_img_url))
        .catch((error) => console.log(error));
    } else {
      setAvatar(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAddress, getWinnerOpenSeaProfile, avatar]);

  return (
    <>
      <div className="container-xl">
        <div
          className={` d-flex justify-content-end align-items-center fixed-top navbar navbar-dark navbar-light navbar-expand-lg ${
            scroll && "bg-dark "
          }`}
        >
          <button
            className={`navbar-toggler align-self-center justify-content-start me-5  ${
              scroll && "mt-0 align-self-start"
            }`}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#collapsibleNavId"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`header-position admin-header-position  ${
              scroll && "mt-0 scroll-animation"
            }`}
          >
            <h1
              className={`text-white header__heading  ${
                scroll && "header__scroll-heading "
              }`}
            >
              <Link to={routes.ADMIN_HOME} className="text-white header-title">
                {eng_lang.title}
              </Link>
            </h1>
            <div className="d-flex tabs w-45 tabs-mobile-none">
              <ul className="navbar-nav w-100 justify-content-end align-items-center">
                <li>
                  <BlockButton
                    showImg={false}
                    text={"Withdraw Amount"}
                    imgPath={""}
                    name={"Withdraw Amount"}
                    handleClick={handleClick}
                    transparent_btn={true}
                    borderRadius
                  />
                </li>
                <Dropdown className="nav-item ml-24">
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    className="border-0 bg-transparent position-relative"
                  >
                    {avatar !== null && avatar !== "" && (
                      <img
                        className="position-absolute admin-status"
                        src={Admin_status}
                        alt="online"
                      />
                    )}

                    <div
                      className={`avatar-profile ${
                        avatar !== null && avatar !== "" ? "br-50" : ""
                      }`}
                    >
                      <img
                        src={
                          avatar !== null && avatar !== ""
                            ? avatar
                            : admin_avatar
                        }
                        alt="avatar"
                        className="avatar-admin"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align={"start"} className="mw-337">
                    {totalMinted >= eng_lang.totalNoOfMintToken ? (
                      <Form className="ms-16 mt-16 enable-form dropdown-basic-btn gochi text-white d-flex justify-content-between mw-90 align-items-center">
                        Reveal Story & Attributes
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          className="text-white"
                          as="input"
                          onClick={handleToggle}
                          label={""}
                          checked={toggleAccessUser}
                          readOnly
                        />
                      </Form>
                    ) : null}
                    <Form className="winner-reveal-form position-relative ms-16 mt-16 enable-form dropdown-basic-btn gochi text-white d-flex justify-content-between mw-90 align-items-center">
                      Reveal Winners
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="text-white"
                        as="input"
                        onClick={handleReveal}
                        label={""}
                        disabled={
                          totalMinted >= eng_lang.totalNoOfMintToken
                            ? false
                            : true
                        }
                        checked={winnerRevealed}
                        feedback={
                          totalMinted < eng_lang.totalNoOfMintToken
                            ? eng_lang.groupSelectPage.revealWinnerErrorFeedback
                            : null
                        }
                        feedbackTooltip
                        readOnly
                      />
                    </Form>

                    <Dropdown.Item
                      as="button"
                      className="ms-0 dropdown-basic-btn gochi text-white"
                      onClick={logout}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
            <AdminDrawer
              handleModal={handleClick}
              avatar={admin_avatar}
              logout={logout}
              handleToggle={handleToggle}
              handleReveal={handleReveal}
              totalMinted={totalMinted}
              toggle={toggleAccessUser}
              adminAvatar={avatar}
              AdminStatus={Admin_status}
              winnerRevealed={winnerRevealed}
            />
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default AdminHeader;
