// libraries
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// constants
import { eng_lang, routes } from "../../../../lib/utills/constants";

const Drawer = ({ windowLocation }) => {
  // get global state values
  const {
    nftsReducer: { toggleAccessUser, totalMinted },
    winnerReducer: { winnerRevealed, isWinner },
  } = useSelector((state) => state);

  return (
    <>
      <div
        className="offcanvas offcanvas-start  justify-content-end"
        id="collapsibleNavId"
        tabIndex="-1"
      >
        <button
          type="button"
          className="btn-close text-reset align-self-end p-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header d-flex justify-content-center mb-2">
          <h1 className="text-white">
            <Link
              to={routes.HOME}
              className="text-white header-title"
              data-bs-dismiss="offcanvas"
            >
              {eng_lang.title}
            </Link>
          </h1>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex tabs">
            <ul className="flex-column nav w-100 ">
              <li className="nav-item">
                <a
                  href={`${
                    windowLocation === routes.HOME
                      ? "#howItWorks"
                      : `${routes.HOME}`
                  }`}
                  className="text-white"
                  data-bs-dismiss={
                    windowLocation === routes.HOME && "offcanvas"
                  }
                >
                  {eng_lang.header.tabs[0]}
                </a>
              </li>
              {toggleAccessUser &&
              totalMinted >= eng_lang.totalNoOfMintToken ? (
                <>
                  <li className="nav-item">
                    <Link
                      to={routes.STORY_INTRO}
                      className="text-white"
                      data-bs-dismiss="offcanvas"
                    >
                      {eng_lang.header.tabs[1]}
                    </Link>
                  </li>
                  {winnerRevealed !== eng_lang.winner_announced || isWinner !== eng_lang.winner_announced ? (
                    <>
                      <li className={`nav-item`}>
                        <Link
                          to={routes.CLAIM_ATTRIBUTE}
                          className={`text-white`}
                          data-bs-dismiss="offcanvas"
                        >
                          {eng_lang.header.tabs[2]}
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drawer;
