import { types } from "../../types";

const initialState = {
  toggle: false,
};

const toggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_ACCESS:
      return {
        toggle: action.payload,
      };
    case types.TOGGLE_FAIL:
      return {
        toggle: action.payload,
      };

    default:
      return state;
  }
};
export default toggleReducer;
