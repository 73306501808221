// libraries
import React, { Fragment } from "react";

// styles
import "./style.scss";

// constants
import { eng_lang } from "../../lib/utills/constants";

const TermsAndConditions = () => {
  return (
    <section className="terms-and-conditions">
      <div className="container-xxl">
        <div className="d-flex justify-content-center flex-column">
          <h2 className="text-white text-uppercase heading">
            {eng_lang.termsAndConditions.heading1}
          </h2>
          <p className="text-uppercase subtitle">
            {eng_lang.termsAndConditions.subtitle}
          </p>
          <p className="paragraph mt-24">
            {eng_lang.termsAndConditions.paragraph_1}
          </p>
          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_2}</p>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading2}
          </h5>

          <ol type="A" className="paragraph dark-grey">
            {eng_lang.termsAndConditions.headings_points.heading2_point.map(
              (points, index) => {
                return (
                  <Fragment key={index}>
                    <li>{points}</li>
                  </Fragment>
                );
              }
            )}
          </ol>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading3}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_3}</p>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading4}
          </h5>

          <ol type="A" className="paragraph dark-grey">
            {eng_lang.termsAndConditions.headings_points.heading4_point.map(
              (points, index) => {
                return (
                  <Fragment key={index}>
                    <li>{points}</li>
                  </Fragment>
                );
              }
            )}
          </ol>
          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading5}
          </h5>

          <ol type="A" className="paragraph dark-grey">
            {eng_lang.termsAndConditions.headings_points.heading5_point.map(
              (points, index) => {
                return (
                  <Fragment key={index}>
                    <li>{points}</li>
                  </Fragment>
                );
              }
            )}
          </ol>
          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading6}
          </h5>

          <ol type="A" className="paragraph dark-grey">
            {eng_lang.termsAndConditions.headings_points.heading6_point.map(
              (points, index) => {
                return (
                  <Fragment key={index}>
                    <li>{points}</li>
                  </Fragment>
                );
              }
            )}
          </ol>
          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading7}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.acknowledge}</p>

          <ol type="A" className="paragraph mt-24 dark-grey">
            {eng_lang.termsAndConditions.headings_points.heading7_point.map(
              (points, index) => {
                return (
                  <Fragment key={index}>
                    <li>{points}</li>
                  </Fragment>
                );
              }
            )}
          </ol>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading8}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_4}</p>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading9}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_5}</p>
          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading10}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_6}</p>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading11}
          </h5>

          <p className="paragraph">{eng_lang.termsAndConditions.paragraph_7}</p>

          <h5 className="text-white text-uppercase mt-24 sub-heading ">
            {eng_lang.termsAndConditions.heading12}
          </h5>
          {eng_lang.termsAndConditions.heading12_paras.map(
            (paragraphs, index) => {
              return (
                <p key={index} className="paragraph mt-24">
                  {paragraphs}
                </p>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;