// libraries
import React from "react";

// components
import TermsAndConditions from "../../components/terms-and-conditions";

const TermsAndConditionsPage = () => {
  return (
    <>
      <TermsAndConditions />
    </>
  );
};

export default TermsAndConditionsPage;
