//libraries
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//pages
import {
  LandingPage,
  StoryUpdate,
  ClaimAttributeSet,
  StoryCluesPage,
  Error,
  TermsAndConditionsPage,
  FAQPage,
} from "./pages";

// admin imports
import {
  AdminLoginPage,
  GroupSelectPage,
  AdminPasswordUpdatePage,
} from "./pages/admin-pages";

//components
import EtheriumProvider from "./services/etherium-blockchain/EtheriumProvider";
import { withHeaderAndFooter } from "./withHeaderAndFooter";
import { withAdminHeader } from "./withAdminHeader";

// private routes
import PrivateRoute from "./private-route/PrivateRoutes";

//constants
import { eng_lang, routes } from "./lib/utills/constants";
import {
  getMintedTokens,
  showModalAction,
  btnLoadingAction,
  getClaimedAmountAction,
  getToggleValueAction,
} from "./redux/actions/nfts";
import {
  displayModalAction,
  suspectBtnLoading,
} from "./redux/actions/claim-attributes";
import { voucherLoading } from "./redux/actions/voucher";

//styles
import "./sass/main.scss";

// Redux Actions
import {
  getWinnerAction,
  getToggleWinnerRevealAction,
} from "./redux/actions/winner-reveal";
import { buyErrorSolved, resetTokenAndMint } from "./redux/actions/buy-flow";

//assets

const App = () => {
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const {
    nftsReducer: { totalMinted, totalClaimed, toggleAccessUser },
    winnerReducer: { winnerRevealed },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getMintedTokens());
    dispatch(getClaimedAmountAction());
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetTokenAndMint());
    dispatch(btnLoadingAction(false));
    dispatch(displayModalAction(""));
    dispatch(suspectBtnLoading(false));
    dispatch(voucherLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getToggleValueAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAccessUser, getToggleValueAction]);

  // to get winner toggle value without refreshing
  useEffect(() => {
    if (totalMinted >= eng_lang.totalNoOfMintToken) {
      dispatch(getToggleWinnerRevealAction());
    }
  }, [dispatch, totalMinted, winnerRevealed]);


  useEffect(() => {
    if (winnerRevealed) {
      dispatch(getWinnerAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, totalClaimed, totalMinted]);

  const listenScrollEvent = () => {
    if (window.scrollY < 70) {
      return setScroll(false);
    } else if (window.scrollY > 70) {
      return setScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <EtheriumProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={routes.HOME}
            element={withHeaderAndFooter(<LandingPage />, scroll)}
          />
          {toggleAccessUser && totalMinted >= eng_lang.totalNoOfMintToken && (
            <>
              <Route
                path={routes.STORY_INTRO}
                element={withHeaderAndFooter(<StoryUpdate />, scroll)}
              />
              <Route
                path={routes.CLUES}
                element={withHeaderAndFooter(<StoryCluesPage />, scroll)}
              />
            </>
          )}
          {toggleAccessUser && totalMinted >= eng_lang.totalNoOfMintToken && (
            <Route
              path={routes.CLAIM_ATTRIBUTE}
              element={withHeaderAndFooter(<ClaimAttributeSet />, scroll)}
            />
          )}

          <Route
            path={routes.TERMS_AND_CONDITIONS}
            element={withHeaderAndFooter(<TermsAndConditionsPage />, scroll)}
          />
          <Route
            path={routes.FAQS}
            element={withHeaderAndFooter(<FAQPage />, scroll)}
          />

          <Route path={routes.ADMIN_LOGIN} element={<AdminLoginPage />} />
          {/* <Route path={routes.ADMIN_HOME} element={withAdminHeader(<GroupSelectPage />)} /> */}

          <Route path="*" element={withHeaderAndFooter(<Error />, scroll)} />

          <Route
            path={routes.ADMIN_HOME}
            element={
              <PrivateRoute>
                {withAdminHeader(<GroupSelectPage />, scroll)}
              </PrivateRoute>
            }
          />
          <Route
            path={routes.ADMIN_UPDATE_PASSWORD}
            element={
              <PrivateRoute>
                <AdminPasswordUpdatePage />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </EtheriumProvider>
  );
};

export default App;