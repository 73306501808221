// libraries
import React from "react";

// constants
import { eng_lang } from "../../../../../../lib/utills/constants";

const CongratsBody = ({ selectedCatGroup, prize }) => {
  return (
    <>
      <h2 className="text-white text-center">{eng_lang.congratulation}</h2>
      <div className="">
        <p className="text-center fs-16 lh-24">
          You have successfully sent the prize amount
        </p>
        <p className="text-white text-center gochi fs-24 lh-24">
          ${selectedCatGroup === eng_lang.groupOne
            ? prize[0]
            : selectedCatGroup === eng_lang.groupTwo
            ? prize[1]
            : selectedCatGroup === eng_lang.groupThree
            ? prize[2]
            : null}
        </p>
      </div>
    </>
  );
};

export default CongratsBody;
