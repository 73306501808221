// libraries
import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { BlockButton } from "../../commons";
import {
  WithDrawBody,
  WithDrawProcessing,
  WithDrawSuccess,
  WithDrawErrorBody,
} from "./withdraw-modal-body";

// constants
import { eng_lang } from "../../../lib/utills/constants";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";
// import { CONTRACT_ADDRESS } from "../../../enviroments";

// redux actions for modal flow
import { showModalAction } from "../../../redux/actions/nfts";
import { buyErrorSolved } from "../../../redux/actions/buy-flow";
import { resetCongrats } from "../../../redux/actions/buy-flow/buyInProgress";

// style
import "./style.scss";

// assets
import {
  ClipperPng,
  blinking_cat,
  success_1,
  success_3,
} from "../../../assets";

const WithDrawPopup = () => {
  const dispatch = useDispatch();
  const {
    withDrawMetamask,
    getContractTokenBalance,
    walletConnection,
    getAdminAddress,
  } = useContext(EtheriumContext);
  const [balance, setBalance] = useState(0);
  const [modalName, setmodalName] = useState("loading");
  const [adminAddress, setAdminAddress] = useState(null);

  const {
    nftsReducer: { showModal, btnLoading },
    buyReducer: { buyInProgress, buyError, congrats },
    metaMaskWalletReducer: { walletConnected, walletAddress },
    authReducer : {accessToken}
  } = useSelector((state) => state);

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const handleClick = () => {
    withDrawMetamask(adminAddress, balance, header);
  };

  const handleClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetCongrats());
  };

  useEffect(() => {
    if (showModal === eng_lang.withDrawModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  useEffect(() => {
    getAdminAddress()
      .then((address) => setAdminAddress(address))
      .catch((error) => console.log(error));
  }, [getAdminAddress]);

  useEffect(() => {
    getContractTokenBalance()
      .then((tokenBalance) => setBalance(Number(tokenBalance)))
      .catch((error) => console.log(error));
  }, [getContractTokenBalance]);

  // show processing modal
  useEffect(() => {
    if (buyInProgress) {
      setmodalName("loading");
    } else if (buyError.error) {
      setmodalName("error");
    } else if (congrats) {
      setmodalName("successful");
    } else {
      setmodalName("withdraw");
    }
  }, [buyInProgress, buyError, congrats]);

  return (
    <>
      {showModal === eng_lang.withDrawModal && (
        <div
          className={`modal fade  sm-modal-withdraw ${
            showModal === eng_lang.withDrawModal && "show d-block"
          }`}
          id="smallModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered  ${
              modalName === "withdraw" ? "wide-modal" : "sm-modal__wide"
            }`}
          >
            <div className="modal-content sm-modal__bg-color">
              <div
                className={`d-flex justify-content-center sm-modal__img-top ${
                  modalName === "withdraw" && "withdraw-top-img"
                }`}
              >
                <img
                  src={
                    modalName === "withdraw"
                      ? ClipperPng
                      : modalName === "loading"
                      ? blinking_cat
                      : modalName === "congrats"
                      ? success_1
                      : modalName === "error"
                      ? success_3
                      : ClipperPng
                  }
                  alt={ClipperPng}
                  className={`sm-modal__cat-img  ${
                    modalName === "loading" && "sm-modal__loading-cat-img"
                  }`}
                />
              </div>
              {modalName === "loading" && <WithDrawProcessing />}

              {modalName !== "loading" && (
                <>
                  <div className="d-flex justify-content-end sm-modal__padding-sm">
                    <button
                      type="button"
                      className="modal__btn-close"
                      data-bs-dismiss="sm-modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <div className="modal-body sm-modal__padding-body">
                    {modalName === "successful" && <WithDrawSuccess />}
                    {modalName === "error" && (
                      <WithDrawErrorBody buyError={buyError} />
                    )}
                    <div className="d-flex flex-column justify-content-between">
                      {modalName === "withdraw" && (
                        <WithDrawBody
                          balance={balance}
                          contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS}
                          wallet={walletAddress}
                          connected={walletConnected}
                          adminAddress={adminAddress}
                        />
                      )}
                    </div>
                    {modalName !== "successful" && (
                      <div className="sm-modal__mg-btw-btns">
                        <BlockButton
                          showImg={false}
                          text={
                            modalName === "error"
                              ? "Try Again"
                              : walletConnected
                              ? "Withdraw"
                              : "Connect Wallet"
                          }
                          imgPath={""}
                          name={"withdraw"}
                          handleClick={
                            walletConnected ? handleClick : walletConnection
                          }
                          disable={
                            walletConnected && balance <= 0
                              ? true
                              : walletConnected &&
                                walletAddress !== adminAddress.toLowerCase()
                              ? true
                              : btnLoading
                          }
                        />
                      </div>
                    )}{" "}
                    {modalName === "successful" && (
                      <div className="sm-modal__mg-btw-btns">
                        <BlockButton
                          showImg={false}
                          text={"Done"}
                          imgPath={""}
                          name={"done"}
                          handleClick={handleClose}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WithDrawPopup;
