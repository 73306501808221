import { types } from "../../types";

const initialState = {
  perPrize: [0, 0, 0],
  totalGroupPrize: [0, 0, 0],
};

const setPrizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PER_PRIZE:
      return {
        ...state,
        perPrize: action.payload,
      };
    case types.SET_TOTAL_PRIZE:
      return {
        ...state,
        totalGroupPrize: action.payload,
      };
    case types.FAIL_SET_PRIZE:
      return {
        perPrize: [0, 0, 0],
        totalGroupPrize: [0, 0, 0],
      };

    default:
      return state;
  }
};
export default setPrizeReducer;
