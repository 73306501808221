// libraries
import React from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// styles
import "./style.scss";

// assets

// constants
import { eng_lang } from "../../../lib/utills/constants";

const BlockButton = ({
  showImg,
  text,
  imgPath,
  name,
  handleClick,
  secondary,
  data_bs_target,
  data_bs_toggle,
  data_bs_slide,
  transparent_btn,
  tooltip,
  data_bs_dismiss,
  disable,
  balanceError,
  borderRadius,
  transactionFailed,
  transactionSuccessful,
  singleTransactionTransferred,
}) => {
  const {
    setAmountReducer: { amount },
    nftsReducer: { totalMinted },
  } = useSelector((state) => state);
  const remainingMinted = eng_lang.totalNoOfMintToken - totalMinted;

  const errMsg = () => {
    if (amount === 0) {
      return `Please Enter Values between 1-${remainingMinted} ( "${remainingMinted}" remaining Blank Cats)`;
    }
    if (amount >= remainingMinted) {
      return "Sorry, there are no more cats available to buy";
    }
  };

  return (
    <div className="d-grid gap-2 block">
      <div className="btn-container btn-block-container position-relative">
        {tooltip === true ? (
          <OverlayTrigger
            placement="top-end"
            overlay={
              <Tooltip id="tooltip-disabled" className="tooltip-custom">
                {errMsg()}
              </Tooltip>
            }
          >
            <span className="d-inline-block w-100">
              <button
                className={`btn w-100 disable-btn ${
                  secondary
                    ? "btn-secondary"
                    : transparent_btn
                    ? "btn-secondary block__bg-tranparent"
                    : ""
                }  block__btn-text tooltip-btn`}
                type="button"
                name={name}
                onClick={handleClick}
                data-bs-target={data_bs_target}
                data-bs-dismiss={data_bs_dismiss}
                data-bs-slide={data_bs_slide}
                disabled={true}
                style={{ pointerEvents: "none" }}
              >
                {showImg && (
                  <img className="btn-img" src={imgPath} alt={imgPath} />
                )}
                {text}
              </button>
            </span>
          </OverlayTrigger>
        ) : balanceError === true ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled" className="tooltip-custom">
                  Invalid Input!
                </Tooltip>
              }
            >
              <span className="d-inline-block w-100">
                <button
                  className={`btn w-100 disable-btn ${
                    secondary
                      ? "btn-secondary"
                      : transparent_btn
                      ? "btn-secondary block__bg-tranparent"
                      : ""
                  }  block__btn-text tooltip-btn`}
                  type="button"
                  name={name}
                  onClick={handleClick}
                  data-bs-target={data_bs_target}
                  data-bs-dismiss={data_bs_dismiss}
                  data-bs-slide={data_bs_slide}
                  disabled={true}
                  style={{ pointerEvents: "none" }}
                >
                  {showImg && (
                    <img className="btn-img" src={imgPath} alt={imgPath} />
                  )}
                  {text}
                </button>
              </span>
            </OverlayTrigger>
          </>
        ) : singleTransactionTransferred === true ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled" className="tooltip-custom">
                  You cannot update the prize because prize has already been
                  transferred to a winner of this group.
                </Tooltip>
              }
            >
              <span className="d-inline-block w-100">
                <button
                  className={`btn w-100 disable-btn ${
                    secondary
                      ? "btn-secondary"
                      : transparent_btn
                      ? "btn-secondary block__bg-tranparent"
                      : ""
                  }  block__btn-text tooltip-btn`}
                  type="button"
                  name={name}
                  onClick={handleClick}
                  data-bs-target={data_bs_target}
                  data-bs-dismiss={data_bs_dismiss}
                  data-bs-slide={data_bs_slide}
                  disabled={true}
                  style={{ pointerEvents: "none" }}
                >
                  {showImg && (
                    <img className="btn-img" src={imgPath} alt={imgPath} />
                  )}
                  {text}
                </button>
              </span>
            </OverlayTrigger>
          </>
        ) : (
          <>
            <span
              className={`mas mas-block ${
                transparent_btn && "btn-secondary block__bg-tranparent"
              } ${transactionFailed && "transferred-failed-btn"} ${
                transactionSuccessful && "transferred-success-btn"
              } ${borderRadius ? "br-4" : ""}`}
            >
              {showImg && (
                <img className="btn-img" src={imgPath} alt={imgPath} />
              )}
              {text}
            </span>
            <button
              className={`btn w-100  ${
                transactionSuccessful && "transferred-success-btn"
              }  ${transactionFailed && "transferred-failed-btn"}  ${
                borderRadius ? "br-4" : ""
              } ${
                secondary
                  ? "btn-secondary"
                  : transparent_btn
                  ? "btn-secondary block__bg-tranparent"
                  : "btn-primary"
              }  block__btn-text`}
              type="button"
              name={name}
              onClick={handleClick}
              data-bs-target={data_bs_target}
              data-bs-toggle={data_bs_toggle}
              data-bs-dismiss={data_bs_dismiss}
              data-bs-slide={data_bs_slide}
              disabled={transactionSuccessful ? true : disable}
            >
              {showImg && (
                <img className="btn-img" src={imgPath} alt={imgPath} />
              )}
              {text}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default BlockButton;
