// axios methods
import axios from "axios";

// // action types
// import { types } from "../../types";

// constants
import { api_routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";
import {
  buyErrorSolved,
  buyInProgressAction,
  buyErrorAction,
} from "../buy-flow";
import { setCongrats } from "../buy-flow/buyInProgress";
import { notfiFail } from "../../../lib/helper/toast";

const updatePrizeAction = (header, prizeAmount, groupName) => {
  return async function (dispatch) {
    try {
      dispatch(buyInProgressAction(true));
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${api_routes.UPDATE_PRIZE}`,
        { groupName: groupName, prizePerCat: prizeAmount },
        { headers: header }
      );
      if (response) {
        dispatch(buyInProgressAction(false));
        dispatch(buyErrorSolved());
        dispatch(setCongrats());
      }
    } catch (err) {
      console.log(err);
      dispatch(buyErrorAction(err?.message));
      notfiFail(err);
      dispatch(buyInProgressAction(false));
    }
  };
};

export default updatePrizeAction;
