import React, { useContext } from "react";

// components
import { BlockButton } from "../../commons";

// constants
import { eng_lang } from "../../../lib/utills/constants";
import { landinPageProps } from "../../../pages/landing-page";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";

// styles
import "./style.scss";

// assets

const CongratSection = () => {
  const { winnerData } = useContext(landinPageProps);
  const { walletAddressTruncater } = useContext(EtheriumContext);
  return (
    <div className="congrats congrats__bg">
      <h3 className="text-white congrats-text">
        {eng_lang.winnerRevealLandingPage.congrat}
      </h3>
      {winnerData?.winners?.map((winner, index) => {
        return (
          <>
            {index === 0 && (
              <>
                <div className="winner-profile-modal">
                  <img
                    src={winner?.owner?.openSeaProfile?.avatarUrl}
                    alt="avatar"
                  />
                </div>
                <h6 className="text-white inter winner">
                  {winner?.owner?.openSeaProfile?.userName !== "Unnamed" &&
                  winner?.owner?.openSeaProfile?.userName !== null
                    ? winner?.owner?.openSeaProfile?.userName
                    : walletAddressTruncater(winner?.owner?.ownerAddress)}
                </h6>
                <p className="mystery-solve-text">
                  {eng_lang.winnerRevealLandingPage.mystery_text}{" "}
                  <span className="text-white bold-prize">
                    {eng_lang.dollar}
                    {winner?.owner?.prizeAmountTransferred?.toLocaleString() ||
                      0}{" "}
                    {eng_lang.usd}{" "}
                  </span>{" "}
                  {eng_lang.winnerRevealLandingPage.bounty}
                </p>
              </>
            )}
          </>
        );
      })}

      <div className="mt-32">
        <BlockButton
          showImg={false}
          imgPath={""}
          text={`Show ${winnerData?.winners?.length - 1} Other ${
            winnerData?.winners?.length > 2 ? "Winners" : "Winner"
          }`}
          data_bs_toggle="modal"
          data_bs_target="#scrollModalOtherWinners"
          data_bs_dismiss="modal"
        />
      </div>
    </div>
  );
};

export default CongratSection;
