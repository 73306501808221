import React from "react";

const SetPrizeErrorBody = ({buyError}) => {
  return (
    <>
      <h2 className="text-white text-center">Setting the Prize Failed</h2>
      <div className="sm-modal__congrats-body-margin">
        <p>{buyError?.message}</p>
      </div>
    </>
  );
};

export default SetPrizeErrorBody;
