//libraries
import React, { createContext } from "react";
import { useSelector } from "react-redux";

//pages

//components
import { SmallPopup } from "../../components/commons";
import {
  HeroSection,
  MintedSection,
  WhoDidIt,
  HowItWorks,
  HowItWorksModal,
} from "../../components/landing-page";
import {
  PopUp,
  ScrollingModal,
  ConfirmationModal,
  ScrollingModalWinners,
} from "../../components/join-the-hunt";
import Toast from "../../lib/helper/toast";

//constants
import { eng_lang } from "../../lib/utills/constants";

//styles

//assets

export const landinPageProps = createContext();

const LandingPage = () => {
  const {
    nftsReducer: { totalMinted, btnLoading, showModal, toggleAccessUser },
    voucherReducer: { voucherLoading, voucher },
    metaMaskWalletReducer: { walletConnected, walletAddress },
    buyReducer: { buyInProgress, buyError, congrats, tokenId },
    winnerReducer: { isWinner, winnerData, winnerRevealed },
    setAmountReducer: { amount },
  } = useSelector((state) => state);

  const remainingMinted = eng_lang.totalNoOfMintToken - totalMinted;

  return (
    <>
      <landinPageProps.Provider
        value={{
          totalMinted,
          remainingMinted,
          walletConnected,
          btnLoading,
          walletAddress,
          voucherLoading,
          voucher,
          buyInProgress,
          buyError,
          congrats,
          showModal,
          tokenId,
          isWinner,
          winnerData,
          winnerRevealed,
          amount,
          toggleAccessUser,
        }}
      >
        <HeroSection />
        <MintedSection />
        <HowItWorks />
        <HowItWorksModal />
        <WhoDidIt />
        <PopUp />
        <SmallPopup />
        <Toast />
        <ScrollingModal />
        <ScrollingModalWinners />
        <ConfirmationModal />
      </landinPageProps.Provider>
    </>
  );
};

export default LandingPage;
