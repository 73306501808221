import { api_routes } from "../../../lib/utills/constants";

import { postRequest } from "../../axiosMethod";

const convertToWei = (web3, value) => {
  return web3.utils.toWei(value.toString());
};

const convertFromWei = (web3, value, to) => {
  return web3.utils.fromWei(value, to);
};

const getAccountAddress = (web3) => {
  return new Promise((resolve, reject) => {
    web3.eth
      .getAccounts()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getAccountBalance = (web3, walletAddress) => {
  return new Promise((resolve, reject) => {
    web3.eth
      .getBalance(walletAddress)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const getTokenBalance = (tokenInstance, ADDRESS) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .balanceOf(ADDRESS)
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getWithDrawalAddress = (tokenInstance) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .withdrawalAddress()
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getPrice = (tokenInstance) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .catPrice()
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSupply = (tokenInstance) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .totalSupply()
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const walletAddressTruncater = (address) => {
  return address?.replace(address?.substring(4, 38), "...");
};

const getAllowance = (tokenInstance, walletAddress, CONTRACT_ADDRESS) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .allowance(walletAddress, CONTRACT_ADDRESS)
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getOwnerAddress = (tokenInstance, nft) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .ownerOf(Number(nft))
      .call()
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const timeConverter = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var time = `${hour}:${min} - ${date}/${month}/${year}`;
  return time;
};

const getBlankNfts = async (address) => {
  let payload = { walletAddress: address };
  const response = await postRequest(
    api_routes.GET_BLANK_NFT_ON_CLAIM,
    payload
  );
  return response;
};

export {
  convertToWei,
  getAccountAddress,
  getAccountBalance,
  convertFromWei,
  getTokenBalance,
  getWithDrawalAddress,
  getPrice,
  getAllowance,
  getOwnerAddress,
  timeConverter,
  getSupply,
  walletAddressTruncater,
  getBlankNfts,
};
