// axios methods
import axios from "axios";

// action types
import { types } from "../../types";

// helpers
import { notfiFail } from "../../../lib/helper/toast";

// constants
import { api_routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";

const toggleWinnerRevealAction = (header) => {
  return async function (dispatch) {
    try {
      // api call to get total tokent minted
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${api_routes.TOGGLE_WINNER_REVEAL}`,
        { headers: header }
      );
      if (response) {
        dispatch({
          type: types.GET_WINNER_REVEAL_ADMIN,
          payload: response?.data?.data?.toggleRevealWinners,
        });
        dispatch({
          type: types.WINNER_STATUS,
          payload: response?.data?.data?.toggleRevealWinners,
        });
      }
    } catch (err) {
      console.log(err);
      notfiFail(err?.response?.data?.error);
      dispatch({
        type: types.GET_WINNER_REVEAL_ADMIN,
        payload: false,
      });
      dispatch({
        type: types.WINNER_STATUS,
        payload: false,
      });
    }
  };
};

export default toggleWinnerRevealAction;
