// libraries
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import axiosRetry from "axios-retry";

// components
import { BlockButton } from "../../../commons";
import SmallPopupTransfer from "./small-modal-transfer-prize";

// constants
// import { OPEN_SEA_API_URL, OPEN_SEA_URL } from "../../../../enviroments";
import { eng_lang } from "../../../../lib/utills/constants";
import { EtheriumContext } from "../../../../services/etherium-blockchain/EtheriumProvider";
import { showModalAction } from "../../../../redux/actions/nfts";

// assets

const NftDetailsAdmin = ({
  chosenSuspect,
  selectedCatGroup,
  setCallApi,
  callApi,
  perPrize,
}) => {
  const dispatch = useDispatch();
  const { getOwnerNFT, timeConverter } = useContext(EtheriumContext);
  const [owner, setOwner] = useState(null);
  const [buyerName, setBuyerName] = useState("");
  const [buyerAvatar, setBuyerAvatar] = useState("");
  // to see if prize is 0
  const [seePrize, setSeePrize] = useState(0);

  // bypass limit of Opensea API by retrying
  axiosRetry(axios, {
    retries: 10, // number of retries
    retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      // return retryCount * 2000; // time interval between retries
      return 2000;
    },
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return error.response.status === 429;
    },
  });

  const getWinnerOpenSeaProfile = useCallback(async () => {
    try {
      if (owner !== null && chosenSuspect?.isClaimed) {
        const resp = await axios.get(
          `${process.env.REACT_APP_OPEN_SEA_API_URL}api/v1/account/${owner}`
        );

        if (resp?.data?.data?.username !== null) {
          return resp?.data?.data;
        }
      }
    } catch (error) {
      setBuyerName("Unnamed");
    }
  }, [chosenSuspect?.isClaimed, owner]);

  useEffect(() => {
    if (chosenSuspect?.isClaimed) {
      getOwnerNFT(chosenSuspect?.nft)
        .then((ownerAddress) => setOwner(ownerAddress))
        .catch((error) => console.log(error));
    } else {
      setOwner(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner, chosenSuspect?.isClaimed, getOwnerNFT, chosenSuspect?.nft]);

  useEffect(() => {
    if (owner !== null) {
      getWinnerOpenSeaProfile()
        .then((res) =>
          res?.user?.username !== null
            ? setBuyerName(res?.user?.username)
            : setBuyerName("Unnamed")
        )
        .catch((error) => console.log(error));
    } else {
      setBuyerName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chosenSuspect?.isClaimed,
    chosenSuspect?.nft,
    getWinnerOpenSeaProfile,
    owner,
    buyerName,
  ]);

  useEffect(() => {
    if (owner !== null) {
      getWinnerOpenSeaProfile()
        .then((res) => setBuyerAvatar(res?.profile_img_url))
        .catch((error) => console.log(error));
    } else {
      setBuyerAvatar("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSuspect?.isClaimed, getWinnerOpenSeaProfile, owner, buyerAvatar]);

  useEffect(() => {
    selectedCatGroup === eng_lang.groupOne
      ? setSeePrize(perPrize[0])
      : selectedCatGroup === eng_lang.groupTwo
      ? setSeePrize(perPrize[1])
      : selectedCatGroup === eng_lang.groupThree
      ? setSeePrize(perPrize[2])
      : setSeePrize(0);
  }, [seePrize, perPrize, selectedCatGroup]);

  const handleClick = () => {
    dispatch(showModalAction(eng_lang.teransferPrizeModal));
  };

  return (
    <>
      <div className="d-flex justify-content-center justify-content-lg-start">
        <img
          className="ml-24 selected-winner"
          src={chosenSuspect?.imagePath}
          alt="cat"
        />
      </div>

      <div className="d-flex w-100 justify-content-between align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.buyer_name}</p>
        <p className="text-white">
          {buyerName !== "Unnamed" &&
          buyerName !== "" &&
          buyerName !== null &&
          buyerName !== undefined
            ? buyerName
            : "N/A"}
        </p>
      </div>

      {chosenSuspect?.isClaimed ? (
        <div className="d-flex w-100 justify-content-between align-content-baseline align-items-baseline ml-24 nft-details">
          <p className="text-white">{eng_lang.groupSelectPage.open_sea_link}</p>
          <a
            href={`${process.env.REACT_APP_OPEN_SEA_URL}${chosenSuspect?.nft}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue gochi"
          >
            {eng_lang.groupSelectPage.click_here}
          </a>
        </div>
      ) : null}

      <div className="d-flex w-100 justify-content-between align-items-baseline  ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.plack_number}</p>
        <p className="text-white">
          {chosenSuspect?.placardNumber?.toString().length === 5
            ? `0${chosenSuspect?.placardNumber}`
            : chosenSuspect?.placardNumber}
        </p>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.hat}</p>
        <p className="text-white">{chosenSuspect?.hat}</p>
      </div>
      <div className="d-flex w-100 justify-content-between  align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.skin}</p>
        <p className="text-white">{chosenSuspect?.skin}</p>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.clothes}</p>
        <p className="text-white text-capitalize">{chosenSuspect?.clothing}</p>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.eyes}</p>
        <p className="text-white">{chosenSuspect?.eyes}</p>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-baseline ml-24 nft-details">
        <p className="text-white">{eng_lang.groupSelectPage.height}</p>
        <p className="text-white">{chosenSuspect?.height}</p>
      </div>

      {chosenSuspect?.isClaimed &&
      chosenSuspect?.prizeTransferStatus === "Transferred" ? (
        <div className="w-100 mt-24 ml-24 transfer-date">
          <p className="gochi text-white text-center">
            {timeConverter(chosenSuspect?.prizeTransferTrxTime)}
          </p>
        </div>
      ) : null}

      <div
        className={`w-100 ml-24 btn-prize-container ${
          chosenSuspect?.isClaimed &&
          chosenSuspect?.prizeTransferStatus === "Transferred"
            ? "mt-4"
            : "mt-24"
        }`}
      >
        <BlockButton
          showImg={false}
          imgPath={""}
          text={
            chosenSuspect?.isClaimed && !chosenSuspect.prizeTransferStatus
              ? eng_lang.buttonConstants.transfer_prize_text
              : chosenSuspect?.isClaimed &&
                chosenSuspect?.prizeTransferStatus === "Transferred"
              ? "Transferred"
              : chosenSuspect?.isClaimed &&
                chosenSuspect?.prizeTransferStatus === "Failed"
              ? "Failed! Try Again"
              : eng_lang.buttonConstants.unclaimed
          }
          disable={
            chosenSuspect?.isClaimed
              ? seePrize === undefined || seePrize === 0
              : true
          }
          handleClick={handleClick}
          transactionFailed={
            chosenSuspect?.isClaimed &&
            chosenSuspect?.prizeTransferStatus === "Failed"
              ? true
              : false
          }
          transactionSuccessful={
            chosenSuspect?.isClaimed &&
            chosenSuspect?.prizeTransferStatus === "Transferred"
              ? true
              : false
          }
        />
      </div>
      <SmallPopupTransfer
        chosenSuspect={chosenSuspect}
        buyerName={buyerName}
        buyerAvatar={buyerAvatar}
        selectedCatGroup={selectedCatGroup}
        owner={owner}
        setCallApi={setCallApi}
        callApi={callApi}
      />
    </>
  );
};

export default NftDetailsAdmin;
