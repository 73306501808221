// libraries
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

// components
import { TermsAndConditionsSection } from "..";
import { BlockButton } from "../../commons";

// style
import "./style.scss";

// constants
import { landinPageProps } from "../../../pages/landing-page";

// assets
import { ClipperPng } from "../../../assets";
import { eng_lang } from "../../../lib/utills/constants";

// redux actions
import {
  buyErrorSolved,
  resetTokenAndMint,
} from "../../../redux/actions/buy-flow";
import { showModalAction } from "../../../redux/actions/nfts";

const ConfirmationModal = () => {
  const { showModal } = useContext(landinPageProps);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(showModalAction(eng_lang.buyModal));
  };

  const handleDecline = () => {
    dispatch(showModalAction(eng_lang.conncetToBuy));
  };

  const handleClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetTokenAndMint());
  };

  useEffect(() => {
    if (showModal === eng_lang.confirmationModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);
  
  return (
    <>
      {showModal === eng_lang.confirmationModal ? (
        <div
          className={`modal fade scroll-modal  ${
            showModal === eng_lang.confirmationModal && "show d-block"
          } `}
          id="confirmationModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable confirm-modal confirm-modal__modal-margin">
            <div className="d-flex justify-content-center sm-modal__img-top">
              <img
                src={ClipperPng}
                alt={"success_1"}
                className={`sm-modal__cat-img `}
              />
            </div>
            <div className="modal-content modal__box">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="modal__btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>

              <div className="modal-body confirm-modal-container">
                <TermsAndConditionsSection />
                <div className="mt-24 choice-btns">
                  <BlockButton
                    showImg={false}
                    text={eng_lang.buttonConstants.accept_btn_text}
                    imgPath={""}
                    name={"agree"}
                    handleClick={handleConfirm}
                  />
                  <BlockButton
                    showImg={false}
                    text={eng_lang.buttonConstants.decline_btn_text}
                    imgPath={""}
                    name={"decline"}
                    handleClick={handleDecline}
                    secondary={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ConfirmationModal;
