import React, { useContext } from "react";

// constants
import { eng_lang } from "../../../lib/utills/constants";
import { landinPageProps } from "../../../pages/landing-page";

// assets

const NftDetails = () => {
  const { winnerData } = useContext(landinPageProps);
  return (
    <>
      {winnerData?.winners?.map((killerCat, index) => {
        return (
          <div className="row" key={index}>
            {index === 0 && (
              <>
                <div className="col-md-5 scroll-modal__bg-img">
                  <img
                    src={killerCat?.suspectedCat?.imagePath}
                    alt="brown_cat"
                  />
                </div>
                <div className="col-md-7 ps-md-5">
                  <h3 className="text-white text-tab-center">{`Cat#${killerCat?.suspectedCat?.nft}`}</h3>
                  <div className="scroll-modal__body-margin">
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.plack_number}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.placardNumber?.toString()
                          .length === 5
                          ? `0${killerCat?.suspectedCat?.placardNumber}`
                          : `${killerCat?.suspectedCat?.placardNumber}`}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.skin}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.skin}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.eyes}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.eyes}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.height}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.height}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.hat}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.hat}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>{eng_lang.winnerRevealLandingPage.clothing}</p>
                      <h5 className="text-white">
                        {killerCat?.suspectedCat?.clothing}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default NftDetails;
