// libraries
import React from "react";

// components
import { AdminPasswordUpdate } from "../../../components/admin-components";

// helper components
import Toast from "../../../lib/helper/toast";

const AdminPasswordUpdatePage = () => {
  return (
    <>
      <AdminPasswordUpdate />
      <Toast />
    </>
  );
};

export default AdminPasswordUpdatePage;
