// libraries
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { BlockButton } from "../../commons";

// constants
// import { eng_lang } from "../../../lib/utills/constants";
import setAmountAction, {
  incrementAmount,
  decrementAmount,
  failSetAmount,
} from "../../../redux/actions/set-amount";
import { landinPageProps } from "../../../pages/landing-page";

// styles
import "./style.scss";

const SetAmountNft = () => {
  const {
    setAmountReducer: { amount },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { remainingMinted } = useContext(landinPageProps);
  return (
    <div className="mt-20 d-flex flex-sm-row flex-column justify-content-between nft-amount-container">
      <BlockButton
        showImg={false}
        text={"-"}
        imgPath={""}
        handleClick={() => {
          if (amount > 0) {
            dispatch(decrementAmount());
          } else {
            dispatch(failSetAmount());
          }
        }}
        tooltip={amount === 0 ? true : false}
        position={"top-end"}
      />
      <input
        id="amount"
        placeholder="enter amount "
        className="flex-grow-1 amount-nft-input gochi"
        type={"text"}
        min={0}
        max={5000}
        value={amount}
        onChange={(event) => {
          // first converting it to string so that first digit can be non zero
          event.target.value.toString();
          dispatch(setAmountAction(Number(event.target.value)));
        }}
      />

      <BlockButton
        showImg={false}
        text={"+"}
        imgPath={""}
        handleClick={() => {
          if (amount < remainingMinted) {
            dispatch(incrementAmount());
          } else {
            dispatch(setAmountAction(1));
          }
        }}
        tooltip={amount >= remainingMinted ? true : false}
        position={"top-end"}
      />
    </div>
  );
};

export default SetAmountNft;
