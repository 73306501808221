import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { getItem, clearAllLocalData } from "../lib/utills/local-storage";
import { authFail } from "../redux/actions/auth-user/authentication";

import { routes } from "../lib/utills/constants";

function PrivateRoute({ children }) {
  const {
    authReducer: { isAuth },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  // get Expiry date of token to automatically log out user after expiration : by getting payload section of token
  const authToken = getItem("accessToken");
  if (authToken) {
    const tokenExp = JSON.parse(atob(authToken?.split(".")[1]));

    if (tokenExp.exp * 1000 < Date.now()) {
      // expired token so redirect to login and clear local storage with expired tokens
      clearAllLocalData();
      dispatch(authFail());
      return <Navigate to={routes.ADMIN_LOGIN} replace={true} />;
    } else {
      return children;
    }
  }
  if (isAuth === false) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={routes.ADMIN_LOGIN} replace={true} />;
  }

  // authorized so return child components
  return children;
}

export default PrivateRoute;
