// libraries
import * as Yup from "yup";

const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

export const passwordValidationSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required!"),
  newPassword: Yup.string()
    .min(6, "New Password must be at least 6 characters!")
    .max(20, "New Password must be at most 20 characters!")
    .required("New Password is required!").matches(
      strongPasswordRegex,
      "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  conPassword: Yup.string().required("Please Confirm New Password!"),
});
