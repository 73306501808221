// libraries
import React from "react";

// styles
import "./style.scss";

// assets

// constants
import { eng_lang } from "../../lib/utills/constants";

const FAQS = () => {
  return (
    <div className="faqs">
      <div className="container-xxl">
        <h2 className="text-white faq-heading text-uppercase">
          {eng_lang.FaqPage.heading}
        </h2>
        <div className="mt-40">
          {eng_lang.FaqPage.questions_answers.length > 0 &&
            eng_lang.FaqPage.questions_answers.map((faq, index) => (
              <div className="faq-section" key={index}>
                {faq.question === "How do I get USD Coin and Ethereum?" ? (
                  <>
                    <h5 className="text-white question">{faq.question}</h5>
                    <p className="text-white ">
                      Below are links to helpful sources for each step of the
                      process! If this is all new to you, don’t worry - it’s
                      easy! Welcome to the world of blockchain technology!
                      <br />
                      <br /> To add USD Coin as a token in your MetaMask wallet
                      (Ethereum is automatically added by default):
                      <br />
                      <a
                        href="https://www.followchain.org/add-usdc-metamask/"
                        target="_blank"
                      >
                        https://www.followchain.org/add-usdc-metamask/
                      </a>
                      <br />
                      <br /> To buy USD Coin or Ethereum, you’ll want to make an
                      account with a crypto exchange.
                      <br /> Coinbase is a fast and easy platform that offers
                      Ethereum and USDC:
                      <br />
                      <a href="https://www.coinbase.com/" target="_blank">
                        https://www.coinbase.com/
                      </a>
                      <br />
                      <br /> To transfer USDC or Ethereum to your Metamask
                      wallet from Coinbase:
                      <br />
                      <a
                        href="https://cryptonews.com/guides/how-to-send-ethereum-from-coinbase-to-metamask-wallet.htm"
                        target="_blank"
                      >
                        https://cryptonews.com/guides/how-to-send-ethereum-from-coinbase-to-metamask-wallet.htm
                      </a>
                    </p>
                  </>
                ) : faq.question === "Do I need a MetaMask wallet?" ? (
                  <>
                    <h5 className="text-white question">{faq.question}</h5>
                    <p className="text-white ">
                      Yes, you will need a Metamask wallet to mint a Starter Cat
                      from our website! Go to{" "}
                      <a href="https://metamask.io/" target="_blank">
                        https://metamask.io/
                      </a>{" "}
                      and download to start the process!
                    </p>
                  </>
                ) : faq.question ===
                  "How much Ethereum will I need for gas fees to mint and customize a WhoDunIt Cat?" ? (
                  <>
                    <h5 className="text-white question">{faq.question}</h5>
                    <p className="text-white ">
                      The gas price may vary, but on November 16, 2022, these
                      would be the following amounts: <br />
                      <br />
                      <ul>
                        <li>To Buy 1 Cat : 0.001308285 Eth = 1.57604 USD</li>
                        <br />
                        <li>To Buy 2 Cats : 0.00133728 Eth = 1.60763 USD</li>
                        <br />
                        <li>To Buy 3 Cats : 0.001366275 Eth = 1.64753 USD</li>
                        <br />
                        <li>
                          To Claim (add) Traits: 0.001119615 Eth = 1.3498 USD
                        </li>
                        <br />
                        <li>
                          The fee report above is only valid for gas fees of
                          15Gwei.
                        </li>
                      </ul>
                    </p>
                  </>
                ) : (
                  <>
                    <h5 className="text-white question">{faq.question}</h5>
                    <p className="text-white ">{faq.answer}</p>
                  </>
                )}
                {/* <h5 className="text-white question">{faq.question}</h5>
                <p className="text-white ">{faq.answer}</p> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQS;