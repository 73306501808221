// libraries
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// styles
import "./style.scss";

// constants
import { eng_lang } from "../../../lib/utills/constants";

// redux actions
import { authAction } from "../../../redux/actions/auth-user";

// validation schema for email and password
import { loginValidationSchema } from "./login-validation-schema/loginValidationSchema";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    nftsReducer: { btnLoading },
  } = useSelector((state) => state);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      dispatch(authAction(values.email, values.password, navigate));
    },
  });
  
  return (
    <div className="admin-login-bg">
      <div className="login-form">
        <h1 className="text-white admin-login-title">{eng_lang.title}</h1>
        <h6 className="gochi stale-grey mt-18 text-center">
          {eng_lang.admin_login_text}
        </h6>
        <form onSubmit={formik.handleSubmit} className="form-inputs">
          <label htmlFor="username" className="text-white inter mt-46">
            Email
          </label>
          <input
            id="email"
            name="email"
            placeholder="Email"
            className="mt-4"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="mt-4 error-msg-forms">{formik.errors.email}</p>
          ) : null}
          <label htmlFor="Password" className="text-white inter mt-24">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            className="mt-4"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <p className="mt-4 error-msg-forms">{formik.errors.password}</p>
          ) : null}
          <div className="btn-container login-btn-container position-relative align-self-center br-4 mt-56">
            <span className="mas mas-login ">
              {eng_lang.buttonConstants.admin_login_btn_text}
            </span>
            <button
              type="submit"
              className=" btn btn-primary login-btn align-self-center gochi"
              disabled={btnLoading}
            >
              {eng_lang.buttonConstants.admin_login_btn_text}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
