// libraries
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// components
import GroupSelectDropdown from "./group-select";
import SubGroupSelectDropdown from "./sub-group-select";
import NftDetailsAdmin from "./nft-details";
import { GroupOne, GroupTwo, GroupThree } from "./groups";
import ScrollToTop from "react-scroll-to-top";

// constamts
import { api_routes, eng_lang } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";

// assets

// styles
import "./style.scss";

// redux actions
import setPrizeAction from "../../../redux/actions/set-prize";
import { resetPrizeAction } from "../../../redux/actions/set-prize";

const GroupSelect = () => {
  const {
    authReducer: { accessToken },
    setPrizeReducer: { perPrize, totalGroupPrize },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const groupSelections = eng_lang.groupSelectPage.group_select_data;
  const subGroupSelections = eng_lang.groupSelectPage.sub_group_select_data;
  const [data, setData] = useState([]);
  const [page, setpage] = useState(1);
  const [pageNotCall, setpageNotCall] = useState(false);
  const [render, setrender] = useState(true);
  const [selectedCatGroup, setSelectedCatGroup] = useState("");
  const [callApi, setCallApi] = useState(false);

  const [groupAttributes, setGroupAttributes] = useState({
    groupName: null,
    subGroupName: null,
  });
  const [selectedCat, setselectedCat] = useState(null);

  const [loading, setloading] = useState(false);

  const [groupName, setGroupName] = useState(null);

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const getGroups = async (pageNumber, previousCats) => {
    try {
      setloading(true);
      let payload = groupAttributes;
      // Removing null keys from object
      payload = Object.fromEntries(
        Object.entries(payload).filter(([_, v]) => v != null)
      );

      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${api_routes.GET_GROUPS}${pageNumber}&pageSize=${eng_lang.adminPageSize}`,
        payload,
        { headers: header }
      );
      if (resp) {
        setloading(false);
        setpageNotCall(false);
        dispatch(setPrizeAction(header));
        let tempArr = [];
        tempArr = [...previousCats, ...resp?.data?.data];
        setData(tempArr);
      }
    } catch (error) {
      setloading(false);
      setpageNotCall(false);
      dispatch(resetPrizeAction());
      console.log(api_routes.GET_GROUPS, "error", error);
    }
  };

  // call api on page state change
  useEffect(() => {
    if (!pageNotCall) {
      getGroups(page, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setpage(1);
    setselectedCat(null);
    setpageNotCall(true);
    setrender(false);
    if (!render) {
      getGroups(1, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupAttributes, callApi]);

  // const findClaimedCats = data.map((cats) => {
  //   return cats?.suspectedCats?.map((cat) => {
  //     return cat;
  //   });
  // });

  // console.log(findClaimedCats);

  // const findTrueClaimedValues = findClaimedCats?.find(
  //   async (claimedCats) => (await claimedCats?.isClaimed) === true
  // )?.length;

  // console.log(findTrueClaimedValues);

  return (
    <div className="cats-group-container">
      <div className="row gx-0">
        {data?.length > 0 ? (
          <>
            <div className="col-lg-10 groups">
              <h3 className="text-white text-md-start text-center">
                {eng_lang.groupSelectPage.select_killer_group}
              </h3>
              <div className="mt-24">
                {groupSelections.map((item, index) => (
                  <GroupSelectDropdown
                    key={index}
                    index={index}
                    options={item.options}
                    element_name={item.element_name}
                    selectedAttributes={groupAttributes}
                    setGroupName={setGroupName}
                    groupName={groupName}
                    setselectedAttributes={setGroupAttributes}
                    loading={loading}
                  />
                ))}
                {groupName === eng_lang.groupThree &&
                  subGroupSelections.map((item, index) => (
                    <SubGroupSelectDropdown
                      key={index}
                      index={index}
                      heading={item.attribute_name}
                      options={item.options}
                      element_name={item.element_name}
                      selectedAttributes={groupAttributes}
                      setGroupName={setGroupName}
                      groupName={groupName}
                      setselectedAttributes={setGroupAttributes}
                      loading={loading}
                    />
                  ))}
              </div>
              {/* Group 1 */}
              {groupName === eng_lang.groupOne || groupName === null ? (
                <GroupOne
                  data={data}
                  perPrize={perPrize}
                  totalGroupPrize={totalGroupPrize}
                  setselectedCat={setselectedCat}
                  setSelectedCatGroup={setSelectedCatGroup}
                  loading={loading}
                />
              ) : null}

              {/* group 2 */}
              {groupName === eng_lang.groupTwo || groupName === null ? (
                <GroupTwo
                  data={data}
                  perPrize={perPrize}
                  totalGroupPrize={totalGroupPrize}
                  setselectedCat={setselectedCat}
                  loading={loading}
                  setSelectedCatGroup={setSelectedCatGroup}
                />
              ) : null}
              {/* group 3 */}
              {groupName === eng_lang.groupThree || groupName === null ? (
                <GroupThree
                  data={data}
                  perPrize={perPrize}
                  totalGroupPrize={totalGroupPrize}
                  setselectedCat={setselectedCat}
                  loading={loading}
                  setSelectedCatGroup={setSelectedCatGroup}
                />
              ) : null}
            </div>
            <div className="col-lg-2">
              {selectedCat && (
                <NftDetailsAdmin
                  data={data}
                  chosenSuspect={selectedCat}
                  selectedCatGroup={selectedCatGroup}
                  setCallApi={setCallApi}
                  callApi={callApi}
                  perPrize={perPrize}
                />
              )}
            </div>
            <ScrollToTop smooth top={71} />
          </>
        ) : loading === false && data?.length === 0 && !render ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h1 className="text-white">No Groups Found!!</h1>
            <h2 className="text-white text-bold mt-16 amatic">
              Please ask the development team to create the winner groups.
            </h2>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GroupSelect;
