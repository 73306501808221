// libraries
import React, { useEffect, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// components
import { BlockButton } from "../../commons";
import {
  SetPrizeBody,
  SetPrizeProcessing,
  SetPrizeErrorBody,
  SetPrizeSuccessful,
} from "./set-prize-modal-body";

// constants
import { eng_lang, api_routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";

// redux actions for modal flow
import { showModalAction } from "../../../redux/actions/nfts";
import { buyErrorSolved } from "../../../redux/actions/buy-flow";
import { resetCongrats } from "../../../redux/actions/buy-flow/buyInProgress";
import setModalDataAction from "../../../redux/actions/set-modal-data";

// style
import "./style.scss";

// assets
import {
  ClipperPng,
  blinking_cat,
  success_1,
  success_3,
} from "../../../assets";
import updatePrizeAction from "../../../redux/actions/update-prize-action";
import setPrizeAction from "../../../redux/actions/set-prize";

const SetPrizePopup = () => {
  const dispatch = useDispatch();
  const { getUSDBalance, walletConnection } = useContext(EtheriumContext);
  const [balance, setBalance] = useState(0);
  const [modalName, setmodalName] = useState("loading");
  const [groupData, setGroupData] = useState([]);
  const [prize, setPrize] = useState([0, 0, 0]);

  const {
    nftsReducer: { showModal },
    buyReducer: { buyInProgress, buyError, congrats },
    setModalDataReducer: { groupName },
    authReducer: { accessToken },
    metaMaskWalletReducer: { walletConnected },
  } = useSelector((state) => state);

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const handleClick = () => {
    dispatch(updatePrizeAction(header, prize, groupName));
  };

  const handleClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetCongrats());
    dispatch(setModalDataAction(""));
  };

  const handleRefreshOnClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetCongrats());
    dispatch(setModalDataAction(""));
    dispatch(setPrizeAction(header));
  };

  useEffect(() => {
    if (showModal === eng_lang.setPrizeModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  // show processing modal
  useEffect(() => {
    if (buyInProgress) {
      setmodalName("loading");
    } else if (buyError.error) {
      setmodalName("error");
    } else if (congrats) {
      setmodalName("successful");
    } else {
      setmodalName("setPrize");
    }
  }, [buyInProgress, buyError, congrats]);

  const getFilteredPrizes = useCallback(async () => {
    try {
      if (groupName !== "") {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}${api_routes.GET_GROUPS}1&pageSize=100`,
          { groupName: groupName },
          { headers: header }
        );

        if (resp) {
          setGroupData(resp?.data?.data);
          if (resp?.data?.data[0]?.prizePerCat) {
            setPrize(resp?.data?.data[0]?.prizePerCat);
          } else {
            setPrize(0);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  useEffect(() => {
    getFilteredPrizes();
    getUSDBalance()
      .then((balance) => setBalance(balance))
      .catch((error) => console.log(error));
  }, [getFilteredPrizes, getUSDBalance]);

  return (
    <>
      {showModal === eng_lang.setPrizeModal && (
        <div
          className={`modal fade  sm-modal-withdraw ${
            showModal === eng_lang.setPrizeModal && "show d-block"
          }`}
          id="smallModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered 
              sm-modal__wide
            `}
          >
            <div className="modal-content sm-modal__bg-color">
              <div className="d-flex justify-content-center sm-modal__img-top">
                <img
                  src={
                    modalName === "setPrize"
                      ? ClipperPng
                      : modalName === "loading"
                      ? blinking_cat
                      : modalName === "congrats"
                      ? success_1
                      : modalName === "error"
                      ? success_3
                      : ClipperPng
                  }
                  alt={ClipperPng}
                  className={`sm-modal__cat-img  ${
                    modalName === "loading" && "sm-modal__loading-cat-img"
                  }`}
                />
              </div>
              {modalName === "loading" && <SetPrizeProcessing />}

              {modalName !== "loading" && (
                <>
                  <div className="d-flex justify-content-end sm-modal__padding-sm">
                    <button
                      type="button"
                      className="modal__btn-close"
                      data-bs-dismiss="sm-modal"
                      aria-label="Close"
                      onClick={
                        modalName === "successful"
                          ? handleRefreshOnClose
                          : handleClose
                      }
                    ></button>
                  </div>
                  <div className="modal-body sm-modal__padding-body">
                    {modalName === "successful" && <SetPrizeSuccessful />}
                    {modalName === "error" && <SetPrizeErrorBody />}
                    <div className="d-flex flex-column justify-content-between">
                      {modalName === "setPrize" && (
                        <SetPrizeBody
                          data={groupData}
                          prize={prize}
                          setPrize={setPrize}
                          balance={balance}
                        />
                      )}
                    </div>
                    {modalName !== "successful" && (
                      <div className="sm-modal__mg-btw-btns">
                        <BlockButton
                          showImg={false}
                          text={
                            walletConnected && modalName === "error"
                              ? "Try Again"
                              : !walletConnected
                              ? "Connect Wallet"
                              : "Set Prize"
                          }
                          imgPath={""}
                          name={"setPrize"}
                          handleClick={
                            walletConnected ? handleClick : walletConnection
                          }
                          balanceError={
                            (walletConnected && balance - prize < 0) ||
                            (walletConnected && prize < 0) ||
                            (walletConnected && prize?.length === 0) ||
                            (walletConnected && isNaN(prize))
                              ? true
                              : false
                          }
                        />
                      </div>
                    )}
                    {modalName === "successful" && (
                      <div className="sm-modal__mg-btw-btns">
                        <BlockButton
                          showImg={false}
                          text={"Done"}
                          imgPath={""}
                          name={"done"}
                          handleClick={handleRefreshOnClose}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SetPrizePopup;
