// libraries
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

//constants
import { eng_lang, routes } from "../../../lib/utills/constants";
import { showModalAction } from "../../../redux/actions/nfts";
import { landinPageProps } from "../../../pages/landing-page";

// styles
import "./index.scss";

//assets
import { Underline } from "../../../assets";

const HeroSection = () => {
  const dispatch = useDispatch();
  const { isWinner, toggleAccessUser, totalMinted, winnerRevealed} =
    useContext(landinPageProps);

  const handleModalOpen = () => {
    dispatch(showModalAction("conncetToBuy"));
  };

  return (
    <div
      className={`hero ${isWinner === eng_lang.winner_announced  && winnerRevealed ? "full-height" : ""
        }`}
    >
      <div className="d-flex hero-content justify-content-lg-end justify-content-center text-white">
        {winnerRevealed !== eng_lang.winner_announced || isWinner !== eng_lang.winner_announced  ? (
          <>
            <div className={`hero-card`}>
              {toggleAccessUser &&
                totalMinted >= eng_lang.totalNoOfMintToken ? (
                <>
                  <div className="story-release-heading">
                    <h1>{eng_lang.storyRelease.heading}</h1>
                    <img
                      className="underline"
                      src={Underline}
                      alt="underline"
                    />
                  </div>
                  <p>{eng_lang.hero.text_after_mint_crossed_5000}</p>
                </>
              ) : (
                <p>{eng_lang.hero.coming_soon_text}</p>
              )}

              {toggleAccessUser &&
                totalMinted >= eng_lang.totalNoOfMintToken ? (
                <div className="btn-container position-relative mt-34">
                  <Link to={routes.STORY_INTRO}>
                    <span className="mas hero-mas">
                      {eng_lang.buttonConstants.begin_hunt}
                    </span>
                    <button
                      className="btn btn-primary hero-card-btn join_hunt_text"
                      data-mdb-ripple-color="primary"
                    >
                      {eng_lang.buttonConstants.begin_hunt}
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="btn-container position-relative mt-34">
                  <span className="mas hero-mas">
                    {eng_lang.buttonConstants.coming_soon}
                  </span>
                  <button
                    className="btn btn-primary hero-card-btn coming_Soon"
                    data-mdb-ripple-color="primary"
                    // onClick={handleModalOpen}
                  >
                    {eng_lang.buttonConstants.coming_soon}
                  </button>
                </div>
              )}
            </div>
          </>
        ): null}
      </div>
    </div>
  );
};

export default HeroSection;
