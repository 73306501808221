// libraries
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { BlockButton } from "../../commons";
import {
  BuyBody,
  ProcessingBody,
  CongratsBody,
  ErrorBody,
} from "./modal-bodies";

// constants
import { eng_lang } from "../../../lib/utills/constants";
import { EtheriumContext } from "../../../services/etherium-blockchain/EtheriumProvider";
import { landinPageProps } from "../../../pages/landing-page";
import {
  buyErrorSolved,
  resetTokenAndMint,
} from "../../../redux/actions/buy-flow";
import { showModalAction } from "../../../redux/actions/nfts";
// import { BASE_OPEN_SEA_URL, OPEN_SEA_URL } from "../../../enviroments";

// style
import "./style.scss";

// assets
import {
  ClipperPng,
  blinking_cat,
  success_1,
  opensea,
  success_2,
  success_3,
} from "../../../assets";

const SmallPopup = () => {
  const dispatch = useDispatch();
  const { buyNft } = useContext(EtheriumContext);
  const {
    authReducer: { accessToken },
  } = useSelector((state) => state);
  const {
    // walletAddress,
    buyInProgress,
    buyError,
    congrats,
    showModal,
    tokenId,
    toggle,
    remainingMinted,
    amount,
  } = useContext(landinPageProps);
  const [modalName, setmodalName] = useState("loading");

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  // show different modal on button clicks
  const handleModalName = () => {
    buyNft(amount, header);
  };

  // show processing modal
  useEffect(() => {
    if (buyInProgress) {
      setmodalName("loading");
    } else if (buyError.error) {
      setmodalName("error");
    } else if (congrats) {
      setmodalName("congrats");
    } else {
      setmodalName("buy");
    }
  }, [buyInProgress, buyError, congrats]);

  const handleClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetTokenAndMint());
  };

  const handleOpenSeaUrl = () => {
    if (amount > 1) {
      window.open(
        `${process.env.REACT_APP_BASE_OPEN_SEA_URL}account`,
        "_blank"
      );
    } else {
      window.open(`${process.env.REACT_APP_OPEN_SEA_URL}${tokenId}`, "_blank");
    }
  };

  useEffect(() => {
    if (showModal === eng_lang.buyModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  return (
    <>
      {toggle !== true && showModal === eng_lang.buyModal && (
        <div
          className={`modal fade sm-modal ${
            showModal === eng_lang.buyModal && "show d-block"
          }`}
          id="smallModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered  ${
              modalName === "loading"
                ? "sm-modal__loading-wide"
                : "sm-modal__wide"
            }`}
          >
            <div className="modal-content sm-modal__bg-color">
              <div className="d-flex justify-content-center sm-modal__img-top">
                <img
                  src={
                    modalName === "buy"
                      ? ClipperPng
                      : modalName === "loading"
                      ? blinking_cat
                      : modalName === "congrats"
                      ? success_1
                      : modalName === "error"
                      ? success_2
                      : buyError.errorType === eng_lang.insufficient_fund
                      ? success_3
                      : ClipperPng
                  }
                  alt={ClipperPng}
                  className={`sm-modal__cat-img ${
                    modalName === "loading" && "sm-modal__loading-cat-img"
                  }`}
                />
              </div>
              {modalName === "loading" && <ProcessingBody />}
              {modalName !== "loading" && (
                <>
                  <div className="d-flex justify-content-end sm-modal__padding-sm">
                    <button
                      type="button"
                      className="modal__btn-close"
                      data-bs-dismiss="sm-modal"
                      aria-label="Close"
                      onClick={handleClose}
                      disabled={buyInProgress}
                    ></button>
                  </div>
                  <div className="modal-body sm-modal__padding-body">
                    {modalName === "buy" && <BuyBody />}
                    {modalName === "error" && <ErrorBody buyError={buyError} />}

                    {modalName === "congrats" && <CongratsBody />}
                    {modalName === "buy" || modalName === "error" ? (
                      <>
                        <BlockButton
                          showImg={false}
                          text={
                            modalName === "buy"
                              ? eng_lang.buy_nft
                              : modalName === "error"
                              ? eng_lang.try_again
                              : "---"
                          }
                          imgPath={""}
                          name={modalName === "buy" ? "loading" : ""}
                          handleClick={
                            modalName === "buy" || modalName === "error"
                              ? handleModalName
                              : () => {}
                          }
                          disable={
                            amount === 0 || amount > remainingMinted
                              ? true
                              : false
                          }
                        />
                      </>
                    ) : null}

                    {modalName === "congrats" && (
                      <>
                        <BlockButton
                          showImg={false}
                          text={eng_lang.buttonConstants.subscribe_btn_text}
                          imgPath={""}
                          name={"Subscribe button"}
                          handleClick={() => {
                            window.open("http://eepurl.com/icjyNH", "_blank");
                          }}
                        />
                        <div className="sm-modal__mg-btw-btns">
                          <BlockButton
                            showImg={true}
                            text={
                              amount > 1
                                ? eng_lang.view_multiple_on_open_sea
                                : eng_lang.view_on_open_sea
                            }
                            imgPath={opensea}
                            name={"opensea"}
                            handleClick={handleOpenSeaUrl}
                            secondary={true}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SmallPopup;
