// constants

import { types } from "../../types";

const initialState = {
  amount: 1,
};

const setAmountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NFT_AMOUNT:
      return {
        amount: action.payload,
      };
    case types.INCREMENT_AMOUNT:
      return {
        amount: state.amount + 1,
      };
    case types.DECREMENT_AMOUNT:
      return {
        amount: state.amount - 1,
      };

    case types.SET_DEFAULT_AMOUNT:
      return {
        amount: action.payload,
      };
    default:
      return state;
  }
};

export default setAmountReducer;
