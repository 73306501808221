// libraries
import React from "react";

// components
import FAQS from "../../components/faq-page";

const FAQPage = () => {
  return (
    <>
      <FAQS />
    </>
  );
};

export default FAQPage;
