// libraries

// axios methods
import axios from "axios";

// action types

// helpers
import { getItem } from "../../../lib/utills/local-storage";
import { notfiFail, notifySuccess } from "../../../lib/helper/toast";
import { authFail } from "./authentication";
import { btnLoadingAction } from "../nfts";

// constants
import { api_routes, routes } from "../../../lib/utills/constants";
// import { API_BASE_URL } from "../../../enviroments";

const passwordUpdateAction = (oldPassword, newPassword, header, navigate) => {
  return async function (dispatch) {
    try {
      const payload = {
        oldPassword,
        newPassword,
      };
      // disable the button to prevent multiple calld
      dispatch(btnLoadingAction(true));

      // api call to update admin password
      if (getItem("accessToken")) {
        let response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/${api_routes.UPDATE_PASSWORD}`,
          payload,
          { headers: header }
        );

        if (response) {
          notifySuccess(response?.data?.message);
          // to make sure the toast message appears before disappering

          setTimeout(() => {
            navigate(routes.ADMIN_LOGIN);
            dispatch(authFail());
            dispatch(btnLoadingAction(false));
          }, 3000);
        }
      } else {
        dispatch(btnLoadingAction(false));
        dispatch(authFail());
        navigate(routes.ADMIN_LOGIN);
      }
    } catch (err) {
      console.log(api_routes.UPDATE_PASSWORD, err);
      notfiFail(err?.response?.data?.error);
      dispatch(btnLoadingAction(false));
    }
  };
};

export default passwordUpdateAction;
