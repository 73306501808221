const transfer = (tokenInstance, walletAddress, prizeAmount, ownerAddress) => {
  return new Promise((resolve, reject) => {
    tokenInstance.methods
      .transfer(ownerAddress, prizeAmount)
      .send({ from: walletAddress })
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default transfer;
