// libraries
import React from "react";

// components

// style
import "./style.scss";

// constants
import { eng_lang } from "../../../../lib/utills/constants";

// assets
import { ClipperPng } from "../../../../assets";

const HowItWorksModal = () => {
  return (
    <>
      <div
        className={`modal fade scroll-modal`}
        id="scrollModalWorks"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal__wide scroll-modal__modal-margin">
          <div className="d-flex justify-content-center sm-modal__img-top">
            <img
              src={ClipperPng}
              alt={"ClipperPng"}
              className={`sm-modal__cat-img `}
            />
          </div>
          <div className="modal-content modal__box">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="modal__btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {}}
              ></button>
            </div>
            <div className="modal-body scroll-modal__padding-body">
              <h2 className="text-white text-center mt-20">How it Works?</h2>
              <div className="mt-40 how-works-content">
                <ol>
                  <li className="text-white inter text-bold">
                    {eng_lang.howItWorksModal.title[0]}
                  </li>
                  <p>{eng_lang.howItWorksModal.first_title_content_paras[0]}</p>
                  <p className="mt-24">
                    {eng_lang.howItWorksModal.first_title_content_paras[1]}
                  </p>
                  <li className="mt-24 text-white inter text-bold">
                    {eng_lang.howItWorksModal.title[1]}
                  </li>
                  <p>
                    {eng_lang.howItWorksModal.second_title_content_paras[0]}
                  </p>
                  <p className="mt-24">
                    {eng_lang.howItWorksModal.second_title_content_paras[1]}
                  </p>
                  <p className="mt-24">
                    {eng_lang.howItWorksModal.second_title_content_paras[2]}
                  </p>
                  <p className="mt-24">
                    {eng_lang.howItWorksModal.second_title_content_paras[3]}
                  </p>
                  <p className="mt-24">
                    {eng_lang.howItWorksModal.second_title_content_paras[4]}
                  </p>
                  <li className="mt-24 text-white inter text-bold">
                    {eng_lang.howItWorksModal.title[2]}
                  </li>
                  <p>{eng_lang.howItWorksModal.third_title_content_para[0]}</p>
                  <ul className="mt-24 dashed text-white inter pl-0">
                    <li>$100,000 for all 6 traits correct</li>
                    <li>$5,000 for 5 out of 6 traits correct</li>
                    <li>$1,000 for 4 out of 6 traits correct</li>
                  </ul>
                  <p className="mt-24">
                    <b className="text-white">PRO TIP! –</b>{" "}
                    {eng_lang.howItWorksModal.third_title_content_para[1]}
                  </p>
                  <p className="mt-24">
                    <b className="text-white">
                      {eng_lang.howItWorksModal.last_title_descr}{" "}
                    </b>
                  </p>
                  <a href="https://youtu.be/huEOsnMRC84" className="text-blue inter gochi" target="_blank">
                    https://youtu.be/huEOsnMRC84
                  </a>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksModal;
