// libraries
import React from "react";

// constants
// import { eng_lang } from "../../../../../../lib/utills/constants";

const ConfirmBody = () => {
  return (
    <>
      <h2 className="text-white text-center">Confirmation</h2>
      <div className="sm-modal__congrats-body-margin">
        <p className=" text-center">Do you confirm the transaction?</p>
      </div>
    </>
  );
};

export default ConfirmBody;
