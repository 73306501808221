// libraries
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

// components
import { BlockButton } from "../../../../commons";
import { CardSkeleton } from "../../../../claim-attribute-set";

// assets

// constants
import { eng_lang } from "../../../../../lib/utills/constants";

// redux actions
import { showModalAction } from "../../../../../redux/actions/nfts";
import setModalDataAction from "../../../../../redux/actions/set-modal-data";

const GroupThree = ({
  data,
  setselectedCat,
  loading,
  onscroll,
  refhook,
  perPrize,
  totalGroupPrize,
  setSelectedCatGroup,
}) => {
  const dispatch = useDispatch();
  const handleSelectedCat = (cat) => {
    setselectedCat(cat);
    setSelectedCatGroup(eng_lang.groupThree);
  };

  const filtered = data.filter((group) => {
    return group.groupName === eng_lang.groupThree;
  });

  const openModal = () => {
    dispatch(showModalAction(eng_lang.setPrizeModal));
    dispatch(setModalDataAction(eng_lang.groupThree));
  };
  return (
    <>
      <div className="mt-51 group-item">
        <div className="d-flex flex-md-row flex-column justify-content-between align-items-center group-info-box">
          <h3 className="text-white text-md-start text-center flex-lg-grow-1">
            {eng_lang.groupSelectPage.groupThree_heading}
          </h3>

          <div className="d-flex m-0 p-0 flex-column align-items-center">
            <h3 className="text-white">
              ${totalGroupPrize[2] ? totalGroupPrize[2]?.toLocaleString() : "0"}
            </h3>
            <p className="gochi reward">
              {eng_lang.groupSelectPage.single_reward}$
              {perPrize[2] ? perPrize[2]?.toLocaleString() : "0"}
            </p>
          </div>

          <div className="ml-32 prize-btn">
            <BlockButton
              showImg={false}
              imgPath={false}
              text={eng_lang.buttonConstants.set_prize_text}
              handleClick={openModal}
              singleTransactionTransferred={
                filtered[0]?.prizeTransferredToWinnersCount &&
                filtered[0]?.prizeTransferredToWinnersCount >= 1
                  ? true
                  : false
              }
            />
          </div>
        </div>

        {filtered.map((suspects) => {
          return suspects?.subGroups?.map((subgrp, index) => {
            return (
              <Fragment key={index}>
                <h5 className="grey mt-24 mb-16 text-lg-start text-center">
                  {subgrp?.name}
                </h5>
                <div
                  className="row pt-16 gx-0 justify-content-lg-start justify-content-center w-100 cats-grp-imgs"
                  onScroll={onscroll}
                  ref={refhook}
                >
                  {subgrp?.suspectedCats?.map((catImages, index) => {
                    return (
                      <Fragment key={index}>
                        <div
                          className="col-lg-3 col-md-6 card-size"
                          onClick={handleSelectedCat.bind(this, catImages)}
                        >
                          {catImages?.prizeTransferStatus === "Transferred" && (
                            <div className="overlay-text-transferred">
                              <p className="gochi">
                                {
                                  eng_lang.buttonConstants
                                    .transfer_successful_text
                                }
                              </p>
                            </div>
                          )}

                          {catImages?.prizeTransferStatus === "Failed" && (
                            <div className="overlay-text-failed">
                              <p className="gochi">
                                {eng_lang.buttonConstants.transfer_failed_text}
                              </p>
                            </div>
                          )}

                          {catImages?.isClaimed === false && (
                            <div className="overlay-text-unclaimed">
                              <p className="gochi">
                                {eng_lang.buttonConstants.unclaimed}
                              </p>
                            </div>
                          )}
                          <img
                            src={catImages?.imagePath}
                            alt="cat"
                            tabIndex="-1"
                          />
                       
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </Fragment>
            );
          });
        })}

        <div
          className="row pt-36 gx-0 justify-content-lg-start justify-content-center w-100 cats-grp-imgs"
          onScroll={onscroll}
          ref={refhook}
        >
          {loading && (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupThree;
