// libraries
import React from "react";
import { useDispatch } from "react-redux";

// components
import { BlockButton } from "../../../../commons";
import { CardSkeleton } from "../../../../claim-attribute-set";

// assets

// constants
import { eng_lang } from "../../../../../lib/utills/constants";

// redux actions
import { showModalAction } from "../../../../../redux/actions/nfts";
import setModalDataAction from "../../../../../redux/actions/set-modal-data";

const GroupOne = ({
  data,
  setselectedCat,
  loading,
  totalGroupPrize,
  setSelectedCatGroup,
}) => {
  const dispatch = useDispatch();
  const handleSelectedCat = (cat) => {
    setselectedCat(cat);
    setSelectedCatGroup(eng_lang.groupOne);
  };
  const filtered = data.filter((group) => {
    return group.groupName === eng_lang.groupOne;
  });

  const openModal = () => {
    dispatch(showModalAction(eng_lang.setPrizeModal));
    dispatch(setModalDataAction(eng_lang.groupOne));
  };

  return (
    <>
      <div className="mt-44 group-item">
        <div className="d-flex flex-md-row flex-column justify-content-between align-items-center group-info-box">
          <h3 className="text-white text-md-start text-center flex-lg-grow-1">
            {eng_lang.groupSelectPage.main_winner_heading}
          </h3>

          <div className="d-flex m-0 p-0 flex-column align-items-center">
            <h3 className="text-white">
              ${totalGroupPrize[0] ? totalGroupPrize[0]?.toLocaleString() : "0"}
            </h3>
          </div>

          <div className="ml-32 prize-btn">
            <BlockButton
              showImg={false}
              imgPath={false}
              text={eng_lang.buttonConstants.set_prize_text}
              handleClick={openModal}
              singleTransactionTransferred={
                filtered[0]?.prizeTransferredToWinnersCount &&
                filtered[0]?.prizeTransferredToWinnersCount >= 1
                  ? true
                  : false
              }
            />
          </div>
        </div>

        <div className="row pt-36 gx-0 justify-content-lg-start justify-content-center w-100 cats-grp-imgs">
          {filtered.map((suspects) => {
            return suspects?.suspectedCats?.map((cat, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 card-size"
                  onClick={handleSelectedCat.bind(this, cat)}
                >
                  {cat?.prizeTransferStatus === "Transferred" && (
                    <div className="overlay-text-transferred">
                      <p className="gochi">
                        {eng_lang.buttonConstants.transfer_successful_text}
                      </p>
                    </div>
                  )}

                  {cat?.prizeTransferStatus === "Failed" && (
                    <div className="overlay-text-failed">
                      <p className="gochi">
                        {eng_lang.buttonConstants.transfer_failed_text}
                      </p>
                    </div>
                  )}

                  {cat?.isClaimed === false && (
                    <div className="overlay-text-unclaimed">
                      <p className="gochi">
                        {eng_lang.buttonConstants.unclaimed}
                      </p>
                    </div>
                  )}

                  <img src={cat?.imagePath} alt="cat" tabIndex="-1" />
                </div>
              );
            });
          })}
          {loading && (
            <>
              <CardSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupOne;
