//libraries
import React from "react";

//pages

//components
import { StoryClues } from "../../components/story-update";

const StoryCluesPage = () => {
  return (
    <>
      <StoryClues />
    </>
  );
};

export default StoryCluesPage;
