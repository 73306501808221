//libraries
import React from "react";

//pages

//components
import { Intro } from "../../components/story-update";

const StoryUpdate = () => {
  return (
    <>
      <Intro />
    </>
  );
};

export default StoryUpdate;
