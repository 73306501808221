const signTransaction = (tokenInstance, claimNft, blankNfts, walletAddress) => {
  return new Promise((resolve, reject) => {
    console.log(blankNfts, claimNft.index);
    try {
      tokenInstance.methods
        .claimCat(blankNfts, claimNft.index)
        .send({
          from: walletAddress
        })
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};

export default signTransaction;
