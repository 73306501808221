// libraries
import React from "react";

// constants
// import { eng_lang } from "../../../../lib/utills/constants";

const SetPrizeSuccessful = () => {
  return (
    <div className="sm-modal__congrats-body-margin d-flex flex-column align-items-center">
      <h3 className="text-white">Set Prize Successful!</h3>
      <p>Prize succesfully set!</p>
    </div>
  );
};

export default SetPrizeSuccessful;