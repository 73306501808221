// libraries
import React from "react";

// components
import { AdminLogin } from "../../../components/admin-components";

// helper components
import Toast from "../../../lib/helper/toast";

const AdminLoginPage = () => {
  return (
    <>
      <AdminLogin />
      <Toast />
    </>
  );
};

export default AdminLoginPage;
