// constants
import { types } from "../../types";

const setAmountAction = (amount) => {
  return function (dispatch) {
    if (amount >= 0) {
      dispatch(setAmountSuccess(amount));
    } else {
      dispatch(failSetAmount());
    }
  };
};

const setAmountSuccess = (nonZeroAmount) => {
  return function (dispatch) {
    dispatch({
      type: types.SET_NFT_AMOUNT,
      payload: nonZeroAmount,
    });
  };
};

export const incrementAmount = () => {
  return function (dispatch) {
    dispatch({
      type: types.INCREMENT_AMOUNT,
    });
  };
};

export const decrementAmount = () => {
  return function (dispatch) {
    dispatch({
      type: types.DECREMENT_AMOUNT,
    });
  };
};

export const failSetAmount = () => {
  return function (dispatch) {
    dispatch({
      type: types.SET_DEFAULT_AMOUNT,
      payload: 0,
    });
  };
};

export default setAmountAction;
