// libraries
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { BlockButton } from "../../../../commons";
import {
  TransferBody,
  ProcessingBody,
  CongratsBody,
  ErrorBody,
  ConfirmBody,
} from "./modal-bodies";

// constants
import { eng_lang } from "../../../../../lib/utills/constants";
import { EtheriumContext } from "../../../../../services/etherium-blockchain/EtheriumProvider";

import {
  buyErrorSolved,
  resetTokenAndMint,
} from "../../../../../redux/actions/buy-flow";
import { showModalAction } from "../../../../../redux/actions/nfts";
import setPrizeAction from "../../../../../redux/actions/set-prize";

// style
import "./style.scss";

// assets
import {
  ClipperPng,
  blinking_cat,
  success_1,
  success_2,
  success_3,
} from "../../../../../assets";

const SmallPopupTransfer = ({
  chosenSuspect,
  buyerName,
  buyerAvatar,
  selectedCatGroup,
  owner,
  setCallApi,
  callApi,
}) => {
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);
  const { getUSDBalance, walletConnection, transferPrize } =
    useContext(EtheriumContext);

  const {
    nftsReducer: { btnLoading, showModal },
    metaMaskWalletReducer: { walletConnected },
    buyReducer: { buyInProgress, buyError, congrats },
    setPrizeReducer: { perPrize },
    authReducer: { accessToken },
  } = useSelector((state) => state);

  const header = {
    authorization: `Bearer ${accessToken}`,
  };

  const [modalName, setmodalName] = useState("loading");

  // show different modal on button clicks
  const handleModalName = () => {
    setmodalName("confirm");
  };

  // show processing modal
  useEffect(() => {
    if (buyInProgress) {
      setmodalName("loading");
    } else if (buyError.error) {
      setmodalName("error");
    } else if (congrats) {
      setmodalName("congrats");
    } else {
      setmodalName("transfer");
    }
  }, [buyInProgress, buyError, congrats]);

  const handleClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetTokenAndMint());
    dispatch(setPrizeAction(header));
  };

  const handleRefreshOnClose = () => {
    dispatch(buyErrorSolved());
    dispatch(showModalAction(""));
    dispatch(resetTokenAndMint());
    dispatch(setPrizeAction(header));
    setCallApi(!callApi);
  };

  useEffect(() => {
    if (showModal === eng_lang.teransferPrizeModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  useEffect(() => {
    getUSDBalance()
      .then((balance) => setBalance(balance))
      .catch((error) => console.log(error));
  }, [getUSDBalance]);

  const handleTransfer = () => {
    if (selectedCatGroup === eng_lang.groupOne) {
      transferPrize(
        perPrize[0],
        owner,
        chosenSuspect,
        header,
        selectedCatGroup,
        buyerName,
        buyerAvatar
      );
    } else if (selectedCatGroup === eng_lang.groupTwo) {
      transferPrize(
        perPrize[1],
        owner,
        chosenSuspect,
        header,
        selectedCatGroup,
        buyerName,
        buyerAvatar
      );
    } else if (selectedCatGroup === eng_lang.groupThree) {
      transferPrize(
        perPrize[2],
        owner,
        chosenSuspect,
        header,
        selectedCatGroup,
        buyerName,
        buyerAvatar
      );
    } else {
      console.log("No group to select prize from");
    }
  };

  return (
    <>
      {showModal === eng_lang.teransferPrizeModal && (
        <div
          className={`modal fade sm-modal ${showModal === eng_lang.teransferPrizeModal && "show d-block"
            }`}
          id="smallModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-dialog-centered ${modalName === "transfer" ? "transfer-modal-height" : ""
              }  ${modalName === "loading"
                ? "sm-modal__loading-wide"
                : "sm-modal__wide"
              }`}
          >
            <div className="modal-content sm-modal__bg-color">
              <div
                className={`d-flex justify-content-center sm-modal__img-top transfer-img-top`}
              >
                <img
                  src={
                    modalName === "transfer"
                      ? ClipperPng
                      : modalName === "loading"
                        ? blinking_cat
                        : modalName === "congrats"
                          ? success_1
                          : modalName === "error"
                            ? success_2
                            : buyError.errorType === eng_lang.insufficient_fund
                              ? success_3
                              : ClipperPng
                  }
                  alt={ClipperPng}
                  className={`sm-modal__cat-img ${modalName === "loading" && "sm-modal__loading-cat-img"
                    }`}
                />
              </div>
              {modalName === "loading" && <ProcessingBody />}

              {modalName !== "loading" && (
                <>
                  <div className="d-flex justify-content-end sm-modal__padding-sm">
                    <button
                      type="button"
                      className="modal__btn-close"
                      data-bs-dismiss="sm-modal"
                      aria-label="Close"
                      onClick={
                        modalName === "congrats" || modalName === "error"
                          ? handleRefreshOnClose
                          : handleClose
                      }
                      disabled={buyInProgress}
                    ></button>
                  </div>
                  <div className="modal-body sm-modal__padding-body">
                    {modalName === "transfer" && (
                      <TransferBody
                        selectedCatGroup={selectedCatGroup}
                        chosenSuspect={chosenSuspect}
                        buyerName={buyerName}
                        balance={balance}
                        prize={perPrize}
                        owner={owner}
                      />
                    )}
                    {modalName === "confirm" && <ConfirmBody />}
                    {modalName === "error" && (
                      <ErrorBody
                        buyError={buyError}
                        prize={perPrize}
                        selectedCatGroup={selectedCatGroup}
                      />
                    )}

                    {modalName === "congrats" && (
                      <CongratsBody
                        selectedCatGroup={selectedCatGroup}
                        prize={perPrize}
                      />
                    )}
                    {modalName === "transfer" && (
                      <>
                        <div className="mt-12">
                          <BlockButton
                            showImg={false}
                            text={
                              walletConnected
                                ? eng_lang.buttonConstants.send_prize
                                : "Connect Wallet"
                            }
                            imgPath={""}
                            name={modalName === "transfer" ? "loading" : ""}
                            handleClick={
                              walletConnected
                                ? handleModalName
                                : walletConnection
                            }
                            disable={
                              walletConnected && balance <= 0
                                ? true
                                : btnLoading
                            }
                          />
                        </div>
                      </>
                    )}
                    {modalName === "error" && (
                      <>
                        <div className="mt-12">
                          <BlockButton
                            showImg={false}
                            text={
                              walletConnected
                                ? eng_lang.try_again
                                : "Connect Wallet"
                            }
                            imgPath={""}
                            name={modalName === "error" ? "error" : ""}
                            handleClick={
                              walletConnected
                                ? handleModalName
                                : walletConnection
                            }
                            disable={
                              walletConnected && balance <= 0
                                ? true
                                : btnLoading
                            }
                          />
                        </div>
                      </>
                    )}

                    {modalName === "congrats" && (
                      <div className="sm-modal__mg-btw-btns">
                        <BlockButton
                          showImg={false}
                          text={"Done"}
                          imgPath={""}
                          name={"done"}
                          handleClick={handleRefreshOnClose}
                        />
                      </div>
                    )}

                    {modalName === "confirm" && (
                      <>
                        <div className="mt-24">
                          <BlockButton
                            showImg={false}
                            text={
                              walletConnected
                                ? "Yes Confirmed"
                                : "Connect Wallet"
                            }
                            imgPath={""}
                            name={modalName === "transfer" ? "loading" : ""}
                            handleClick={
                              walletConnected
                                ? handleTransfer
                                : walletConnection
                            }
                          />
                        </div>
                        <div className="mt-16">
                          <BlockButton
                            showImg={false}
                            text={"Not Yet"}
                            imgPath={""}
                            name={modalName === "transfer" ? "loading" : ""}
                            handleClick={() => {
                              setmodalName("transfer");
                            }}
                            secondary
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SmallPopupTransfer;
