// action types
import { types } from "../../types";

// actions
import { suspectBtnLoading, displayModalAction } from ".";

// constants
import { eng_lang } from "../../../lib/utills/constants";

const claimNftSuccess = (nftIndex, successMessage) => {
  return function (dispatch) {
    dispatch({
      type: types.NFT_INDEX,
      payload: nftIndex,
    });
    dispatch({
      type: types.CLAIM_SUCCESS_MSG,
      payload: successMessage,
    });
    dispatch(displayModalAction(eng_lang.claim_nft));
    dispatch(suspectBtnLoading(false));
  };
};

export default claimNftSuccess;
