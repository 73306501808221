// libraries

// axios methods
import { postRequest } from "../../../services/axiosMethod";

// action types
import { types } from "../../types";

// helpers
import { setItem, clearAllLocalData } from "../../../lib/utills/local-storage";
import { btnLoadingAction } from "../nfts";

// constants
import { api_routes, routes } from "../../../lib/utills/constants";

const authAction = (email, password, navigate) => {
  return async function (dispatch) {
    try {
      const payload = {
        email,
        password,
      };

      // disable login button before api call and enable after success
      dispatch(btnLoadingAction(true));
      // api call to get authenticated
      let response = await postRequest(api_routes.AUTH_USER, payload);

      if (response) {
        dispatch(btnLoadingAction(false));
        setItem("accessToken", response?.data?.accessToken);
        dispatch(authSuccess(response?.data?.accessToken));
        navigate(routes.ADMIN_HOME);
      } else {
        dispatch(btnLoadingAction(false));

        clearAllLocalData();
      }
    } catch (err) {
      console.log("auth api error: ", err);
      dispatch(authFail());
      dispatch(btnLoadingAction(false));
    }
  };
};

const authSuccess = (token) => {
  return function (dispatch) {
    dispatch({
      type: types.AUTH_USER,
      payload: token,
    });
  };
};

export const authFail = () => {
  return function (dispatch) {
    dispatch({
      type: types.AUTH_FAIL,
    });
    clearAllLocalData();
  };
};

export default authAction;
