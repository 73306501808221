const approveAmount = (
  tokenInstance,
  contractAddress,
  amount,
  walletAddress
) => {
  if (amount > 0) {
    return new Promise((resolve, reject) => {
      tokenInstance.methods
        .approve(contractAddress, amount)
        .send({
          from: walletAddress,
        })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    return;
  }
};

export default approveAmount;
